import { ITransportAccessibilityWithOrderSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/TransportAccessibilityWithOrderSchema';
import { IBaseTransportAccessibilityItemSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure_by_newbuilding/BaseTransportAccessibilityItemSchema';
import { IMetroSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure_by_newbuilding/MetroSchema';
import { ITransportStopSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure_by_newbuilding/TransportStopSchema';
import {
  INFRASTRUCTURE_TYPE_TO_TITLE_MAP,
  INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
  OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
  POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES,
  POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES,
} from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import {
  ITAInfrastructure,
  TTAInfrastructureItem,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

export const transformTransportAccessibilityInfrastructure = (
  response: ITransportAccessibilityWithOrderSchema | undefined,
): ITAInfrastructure | undefined => {
  if (!response) {
    return undefined;
  }

  const { inside, outside } = response;

  const outsideResult: Record<string, TTAInfrastructureItem[]> = {};
  const insideResult: Record<string, TTAInfrastructureItem[]> = {};

  if (outside) {
    POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES.forEach(infrastructureType => {
      const property = OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType];
      const responseItems = outside[property];

      if (Array.isArray(responseItems)) {
        outsideResult[property] = responseItems.map(item => transformItem(item, infrastructureType));
      }
    });
  }

  if (inside) {
    POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES.forEach(infrastructureType => {
      const property = INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType];
      const responseItems = inside[property];

      if (Array.isArray(responseItems)) {
        const resultItems = responseItems.map(item => transformItem(item, infrastructureType));

        insideResult[property] = resultItems;
      }
    });
  }

  return {
    inside: insideResult,
    outside: outsideResult,
  };
};

function transformItem(
  item: IBaseTransportAccessibilityItemSchema | ITransportStopSchema | IMetroSchema,
  infrastructureType: TTAInfrastructureType,
): TTAInfrastructureItem {
  return {
    ...item,
    type: infrastructureType,
    title: ('title' in item && (item.title as string)) || INFRASTRUCTURE_TYPE_TO_TITLE_MAP[infrastructureType],
  };
}
