import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';
import { getMapYears } from 'shared/mf-infrastructure/utils/getMapYears';

interface ITrackYearClickParams {
  blockType: string;
  objectType: string | null;
  year: number;
  profile: string | null;
}

export function trackYearClick({ blockType, objectType, year, profile }: ITrackYearClickParams) {
  const mapYears = getMapYears();
  const position = mapYears.indexOf(year) + 1;

  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'FutureInfrastructure',
    label: 'timeline',
    name: 'oldevent',
    page: {
      extra: {
        profile,
        block_type: blockType,
        position,
        year,
        object_type: objectType,
      },
    },
    useLastProducts: true,
  });
}
