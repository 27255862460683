import { TInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureItem } from 'shared/mf-infrastructure/types/transportAccessibility';

export function getDateText(infrastructureItem: TInfrastructureItem | TTAInfrastructureItem): string | undefined {
  if ('constructionDateText' in infrastructureItem) {
    return infrastructureItem.constructionDateText;
  }

  return undefined;
}
