import clsx from 'clsx';
import * as React from 'react';

import { VerticalSlider } from 'shared/mf-infrastructure/components/VerticalSlider';
import { VerticalSmallArrow } from 'shared/mf-infrastructure/components/VerticalSlider/VerticalSmallArrow';

import * as style from './style.css';

interface IInfrastructureTypesLayoutProps {
  fixed: boolean;
  children: React.ReactNode;
}

export const InfrastructureTypesLayout: React.FC<IInfrastructureTypesLayoutProps> = ({ fixed, children }) => {
  return (
    <div className={clsx(style['wrapper'], fixed && style['fixed'])}>
      <VerticalSlider arrow={VerticalSmallArrow}>{children}</VerticalSlider>
    </div>
  );
};
