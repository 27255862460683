import { Tabs } from '@cian/ui-kit/prev';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DepartmentLink,
  TransportAccessibilitySidebar,
} from 'shared/mf-infrastructure/components/TransportAccessibility';
import { TransportAcessibilityReport } from 'shared/mf-infrastructure/containers/TransportAccessibilityReport/TransportAcessibilityReport';
import { selectActiveTab } from 'shared/mf-infrastructure/selectors/transportAccessibility';
import { transportAccessibilityActions } from 'shared/mf-infrastructure/slices';
import { IInfrastructure } from 'shared/mf-infrastructure/types/infrastructure';
import {
  ETransportAccessibilityTab,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';
import { ITransportAccessibilityRate } from 'shared/mf-infrastructure/types/transportAccessibilityTypes';

import { useTransportAccessibilityTabs } from './hooks';
import { trackDepartmentLinkClick, trackTabChange } from '../InfrastructureContainer/tracking';
import { TransportAccessibilityInside } from '../TransportAccessibilityInside';
import { TransportAccessibilityOutside } from '../TransportAccessibilityOutside';

export interface ITransportAccessibilitySidebarContainerProps {
  transportAccessibilityRate: ITransportAccessibilityRate | null;
  onClose: () => void;
  onTransportAccessibilityChange: (infrastructureType: TTAInfrastructureType | null) => void;
  infrastructureRef: React.MutableRefObject<IInfrastructure>;
  fixed: boolean;
}

export const TransportAccessibilitySidebarContainer: React.FC<ITransportAccessibilitySidebarContainerProps> = ({
  transportAccessibilityRate,
  onClose,
  onTransportAccessibilityChange,
  infrastructureRef,
  fixed,
}) => {
  const tabs = useTransportAccessibilityTabs(transportAccessibilityRate);
  const activeTab = useSelector(selectActiveTab);

  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    const initialTab = tabs[0];

    if (initialTab) {
      dispatch(transportAccessibilityActions.setActiveTab(initialTab.value));
    }
  }, [dispatch]);

  const changeTab = React.useCallback(
    (tab: string) => {
      dispatch(transportAccessibilityActions.setActiveTab(tab));
      trackTabChange(tab);
    },
    [dispatch],
  );

  const handleDepartmentLinkClick = React.useCallback(() => trackDepartmentLinkClick(activeTab as string), [activeTab]);

  const hasVisibileTabs = tabs.length > 1;

  const departmentLink = transportAccessibilityRate?.sourceUrl || '/';

  return (
    <TransportAccessibilitySidebar
      fixed={fixed}
      onClose={onClose}
      tabs={hasVisibileTabs && <Tabs activeTab={activeTab as string} onChange={changeTab} tabs={tabs} />}
      transportDepartmentLink={
        <DepartmentLink href={departmentLink} size="small" onClick={handleDepartmentLinkClick} />
      }
    >
      {activeTab === ETransportAccessibilityTab.Outside && (
        <TransportAccessibilityOutside
          onTransportAccessibilityChange={onTransportAccessibilityChange}
          rate={
            <TransportAcessibilityReport
              departmentLink={departmentLink}
              transportAccessibilityRate={transportAccessibilityRate}
            />
          }
          transportAccessibilityInfrastructure={infrastructureRef.current.transportAccessibilityInfrastructure}
        />
      )}
      {activeTab === ETransportAccessibilityTab.Inside && (
        <TransportAccessibilityInside
          onTransportAccessibilityChange={onTransportAccessibilityChange}
          rate={
            <TransportAcessibilityReport
              departmentLink={departmentLink}
              inside
              transportAccessibilityRate={transportAccessibilityRate}
            />
          }
          transportAccessibilityInfrastructure={infrastructureRef.current.transportAccessibilityInfrastructure}
        />
      )}
    </TransportAccessibilitySidebar>
  );
};
