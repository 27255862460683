import {
  fetchAjaxMapRoundabout,
  IAjaxMapRoundaboutRequest,
  IAjaxMapRoundaboutResponse,
} from 'shared/common/repository-manually-added/monolith-python/ajax/map/roundabout';
import { restApiFunctionWrapper } from 'shared/common/utils/restApiWrapper/restApiWrapper';

export const fetchSimilarObjects = restApiFunctionWrapper<IAjaxMapRoundaboutRequest, IAjaxMapRoundaboutResponse>(
  fetchAjaxMapRoundabout,
);
