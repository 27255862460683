import { UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './TransportAccessibility.css';
import { DepartmentLink } from '../../DepartmentLink';
import { OutsideIcon } from '../../OutsideIcon';
import { RateMark, TermRateIndicator } from '../parts';

export interface ITransportTerm {
  title: string;
  rateLabel?: string;
  rateIndex: number;
  rateText: string;
}

export interface ITransportAccessibilityProps {
  departmentLink: string;
  maxRate: string;
  onDepartmentLinkClick?: () => void;
  rate: string;
  terms: ITransportTerm[];
}

export const TransportAccessibility: React.FC<ITransportAccessibilityProps> = ({
  departmentLink,
  maxRate,
  onDepartmentLinkClick,
  rate,
  terms,
}) => {
  return (
    <>
      <div className={styles['department-link-wrapper']}>
        <DepartmentLink href={departmentLink} onClick={onDepartmentLinkClick} size="large" />
      </div>
      <div className={styles['rate-mark-wrapper']}>
        <RateMark icon={<OutsideIcon />} maxRate={maxRate} rate={rate} />
      </div>
      <div className={styles['terms']} data-testid="TransportAccessibilityTerms">
        {terms.map((term, idx) => (
          <React.Fragment key={idx}>
            <div className={styles['label']}>
              <UIText1>{term.title}</UIText1>
            </div>
            <div className={styles['indicator']}>
              <TermRateIndicator rate={term.rateIndex} ariaLabel={term.rateLabel} />
            </div>
            <div className={styles['rate']}>
              <UIText2>{term.rateText}</UIText2>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
