import { IInfrastructureData } from 'shared/mf-infrastructure/types/infrastructure';
import { ITAInfrastructureData, TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';

export function toGeoObjects(
  infrastructureByYear: Map<number, IInfrastructureData>,
  year: number,
  onlyExisting = true,
) {
  const geoObjectsData = infrastructureByYear.get(year)?.geoObjectsData || [];

  if (onlyExisting) {
    return geoObjectsData
      .filter(datum => !(datum.infrastructureItem && 'constructionYearFrom' in datum.infrastructureItem))
      .map(datum => datum.geoObject);
  }

  return geoObjectsData.map(datum => datum.geoObject);
}

export function toTransportAccessibilityGeoObjects(
  transportAccessibilityInfrastructureByType: Map<TTAInfrastructureType, ITAInfrastructureData>,
  infrastructureType: TTAInfrastructureType,
) {
  const geoObjectsData = transportAccessibilityInfrastructureByType.get(infrastructureType)?.geoObjectsData || [];

  return geoObjectsData.map(datum => datum.geoObject);
}
