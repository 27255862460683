import { IconButton, IconClose16, UIHeading2 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import { TRANSPORT_ACCESSIBILITY_SIDEBAR_WIDTH } from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';

import * as styles from './TransportAccessibilitySidebar.css';
import { TransportDepartmentIcon } from './parts';
import { InfoPlate } from '../InfoPlate';

export interface ITransportAccessibilitySidebarProps {
  onClose: () => void;
  tabs: React.ReactNode;
  transportDepartmentLink: React.ReactNode;
  fixed: boolean;
}

export const TransportAccessibilitySidebar: React.FC<React.PropsWithChildren<ITransportAccessibilitySidebarProps>> = ({
  children,
  onClose,
  tabs,
  transportDepartmentLink,
  fixed,
}) => {
  return (
    <div
      className={clsx(styles['container'], fixed && styles['fixed'])}
      style={{ width: `${TRANSPORT_ACCESSIBILITY_SIDEBAR_WIDTH}px` }}
    >
      <div className={styles['close-button']}>
        <IconButton icon={<IconClose16 />} onClick={onClose} />
      </div>
      <div className={styles['header']}>
        <UIHeading2>Транспортная доступность</UIHeading2>
      </div>
      {tabs && (
        <div className={styles['tabs-wrapper']} data-testid="TransportAccessibilityTabs">
          {tabs}
        </div>
      )}
      <div className={styles['content-wrapper']}>
        {children}
        <div className={styles['data-from']}>
          <InfoPlate icon={<TransportDepartmentIcon />} content={transportDepartmentLink} withHorizontalMargin />
        </div>
      </div>
    </div>
  );
};
