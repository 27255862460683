import { WebComponent } from '@cian/mf-registry/browser';
import { hydrateRoot, createRoot } from 'react-dom/client';

import { Application } from 'shared/mf-infrastructure/containers/App';
import { IApplicationContext } from 'shared/mf-infrastructure/types/applicationContext';
import { TReduxStore } from 'shared/mf-infrastructure/types/redux';

export function createCustomElementConstructor(
  context: IApplicationContext,
  store: TReduxStore,
): CustomElementConstructor {
  class Infrastructure extends WebComponent {
    private applicationContext: IApplicationContext;

    protected microfrontendName = context.config.getStrict<string>('projectName');
    protected imageVersion = context.config.getStrict<string>('version.commit');
    protected runtimeName = '/public/v1/get-infrastructure-microfrontend/';

    public async connectedCallback(): Promise<void> {
      const s = super.connectedCallback();
      this.applicationContext = { ...context, config: context.config.attach({ instanceId: this.instance.id }) };

      return s;
    }

    public async render() {
      const reactElement = <Application {...this.props} context={this.applicationContext} reduxStore={store} />;

      if (this.shouldHydrate) {
        hydrateRoot(this, reactElement);
      } else {
        createRoot(this).render(reactElement);
      }
    }
  }

  return Infrastructure;
}
