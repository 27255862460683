import cx from 'clsx';
import { Children, FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'throttle-debounce';

import { usePrevious } from 'shared/mf-infrastructure/hooks';

import { VerticalSmallArrow, IVerticalSmallArrowProps } from './VerticalSmallArrow';
import { SCROLL_CLICK_DEAD_ZONE, SCROLL_TROTTLING_DELAY } from './consts';
import * as styles from './styles.css';
import { getLeftScrollOffset, getRightScrollOffset } from './utils/getScrollOffsets';
import { scrollToPos } from './utils/scrollTo';

export interface IVerticalSliderProps {
  arrowOffset?: number;
  arrow?: FC<IVerticalSmallArrowProps>;
  containerClass?: string;
  arrowPositionTop?: string;

  onItemClick?(index: number): void;
}

export function VerticalSlider({
  onItemClick,
  containerClass,
  arrowOffset,
  arrow: Arrow = VerticalSmallArrow,
  arrowPositionTop,
  children,
}: PropsWithChildren<IVerticalSliderProps>) {
  const mountedRef = useRef<boolean>(false);
  const scrollRef = useRef<HTMLUListElement>(null);
  const startXRef = useRef<number>(0);
  const scrollLeftRef = useRef<number>(0);
  const isDraggingRef = useRef<boolean>(false);

  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(false);

  const handleMove = useCallback((e: MouseEvent) => {
    const container = scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      if (Math.abs(e.pageX - startXRef.current) >= SCROLL_CLICK_DEAD_ZONE) {
        isDraggingRef.current = true;
      }

      const x = e.pageX - container.offsetLeft;
      const walk = x - startXRef.current;

      container.scrollLeft = scrollLeftRef.current - walk;

      if (container.scrollLeft <= 0) {
        startXRef.current = x;
        scrollLeftRef.current = 0;
      }

      const hiddenWidth = container.scrollWidth - container.offsetWidth;

      if (container.scrollLeft >= hiddenWidth) {
        startXRef.current = x;
        scrollLeftRef.current = hiddenWidth;
      }
    }
  }, []);

  const handleMoveEnd = useCallback(() => {
    window.removeEventListener('mousemove', handleMove);
    window.removeEventListener('mouseup', handleMoveEnd);
  }, [handleMove]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      const container = scrollRef.current;

      /* istanbul ignore else */
      if (container) {
        startXRef.current = e.pageX - container.offsetLeft;
        scrollLeftRef.current = container.scrollLeft;

        window.addEventListener('mousemove', handleMove);
        window.addEventListener('mouseup', handleMoveEnd);
      }

      isDraggingRef.current = false;
    },
    [handleMove, handleMoveEnd],
  );

  const handleItemClick = useCallback(
    (i: number) => (e: React.MouseEvent) => {
      if (isDraggingRef.current) {
        e.stopPropagation();

        return;
      }

      /* istanbul ignore else */
      if (onItemClick) {
        onItemClick(i);
      }
    },
    [onItemClick],
  );

  const handleScroll = useCallback(() => {
    const container = scrollRef.current;
    const leftChild = container?.firstChild as HTMLLIElement | null;
    const rightChild = container?.lastChild as HTMLLIElement | null;

    /* istanbul ignore else */
    if (leftChild && rightChild && container) {
      const leftBorderPosition = container.scrollLeft;
      const rightBorderPosition = container.offsetWidth + container.scrollLeft;
      const showLeftArrow = leftChild.offsetLeft - container.offsetLeft < leftBorderPosition;
      const showRightArrow =
        rightChild.offsetLeft + rightChild.offsetWidth - container.offsetLeft > rightBorderPosition;

      if (isLeftArrowVisible !== showLeftArrow) {
        setIsLeftArrowVisible(showLeftArrow);
      }

      if (isRightArrowVisible !== showRightArrow) {
        setIsRightArrowVisible(showRightArrow);
      }
    }
  }, [isLeftArrowVisible, isRightArrowVisible]);

  const throttledHandleScroll = useMemo(() => throttle(SCROLL_TROTTLING_DELAY, handleScroll), [handleScroll]);

  const onRightArrowClick = useCallback(() => {
    const container = scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = getRightScrollOffset(container) as number;

      scrollToPos(container, scrollOffset, 300);
    }
  }, []);

  const onLeftArrowClick = useCallback(() => {
    const container = scrollRef.current;

    /* istanbul ignore else */
    if (container) {
      const scrollOffset = getLeftScrollOffset(container) as number;

      scrollToPos(container, scrollOffset, 300);
    }
  }, []);

  const nextWidth = scrollRef.current?.scrollWidth;
  const previousWidth = usePrevious(nextWidth);

  useEffect(() => {
    // В случае изменения ширины контейнера, необходимо пересчитать видимость стрелок.
    if (previousWidth && previousWidth !== nextWidth) {
      handleScroll();
    }
  }, [handleScroll, nextWidth, previousWidth]);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;

      handleScroll();
    }
  }, [handleScroll]);

  return (
    <div className={styles['wrapper']}>
      <ul
        className={cx(styles['container'], containerClass)}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onScroll={throttledHandleScroll}
        data-testid="VerticalSliderContent"
      >
        {Children.map(
          children,
          (child, i) =>
            child && (
              <li key={i} className={cx(styles['item'])} onClick={handleItemClick(i)}>
                {child}
              </li>
            ),
        )}
      </ul>
      {isLeftArrowVisible && (
        <Arrow direction={-1} offset={arrowOffset} positionTop={arrowPositionTop} onClick={onLeftArrowClick} />
      )}
      {isRightArrowVisible && (
        <Arrow direction={1} offset={arrowOffset} positionTop={arrowPositionTop} onClick={onRightArrowClick} />
      )}
    </div>
  );
}
