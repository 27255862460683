import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

export function trackInfrastructureShow(blockType = 'standard', profile: string | null) {
  decoratedCa('eventSite', {
    action: 'show_sopr',
    category: 'Infrastructure',
    name: 'oldevent',
    useLastProducts: true,
    page: {
      extra: { block_type: blockType, profile },
    },
  });
}
