import { ERouteControlViewType } from '@cian/frontend-newbuilding-map-component';
import { useIsDesktop } from '@cian/valuation-utils-component';
import * as React from 'react';

import { IInfrastructureProps } from 'shared/mf-infrastructure/containers/InfrastructureContainer/types';
import { IInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { IRoutingBuildSuccessParams } from 'shared/mf-infrastructure/types/map';

import { IAnalyticsParams } from './useAnalyticsParams';
import { trackRouteStart, trackRouteModeExit, trackRouteSuccess } from '../tracking';

interface IUseRouteButtonProps {
  mapRef: IInfrastructureProps['mapRef'];
  centeredCoordinates: IInfrastructureProps['centeredCoordinates'];
  analyticsParams: IAnalyticsParams;
}

interface IIUseRouteButtonResponse {
  startBuildingRoute: (item?: IInfrastructureItem, routeControlViewType?: ERouteControlViewType) => void;
  isRouteMode: boolean;
  handleRouteModeEnter: () => void;
  handleRouteModeExit: () => void;
  handleRouteSuccess: (params: IRoutingBuildSuccessParams) => void;
}

export const useRouteButtonProps = ({
  mapRef,
  centeredCoordinates,
  analyticsParams,
}: IUseRouteButtonProps): IIUseRouteButtonResponse => {
  const infrastructureItemRef = React.useRef<IInfrastructureItem | null>(null);

  const isDesktop = useIsDesktop();
  const viewType = isDesktop ? ERouteControlViewType.default : ERouteControlViewType.stickyBottom;

  const [isRouteMode, setRouteMode] = React.useState(false);

  const handleRouteModeEnter = React.useCallback(() => {
    setRouteMode(true);
  }, []);

  const handleRouteModeExit = React.useCallback(() => {
    setRouteMode(false);
    trackRouteModeExit();
  }, []);

  const handleRouteSuccess = React.useCallback(
    (searchResult: IRoutingBuildSuccessParams) => {
      if (searchResult.routes.length) {
        trackRouteSuccess({
          blockType: analyticsParams.blockType,
          objectType: analyticsParams.objectType,
          profile: analyticsParams.profile,
          regularInfrastructureItem: infrastructureItemRef.current,
          isTransportAccessibilityServiceOpened: analyticsParams.isTransportAccessibilityServiceOpened,
        });

        infrastructureItemRef.current = null;
      }
    },
    [analyticsParams],
  );

  const startRouting = React.useCallback(
    (geoItem?: IInfrastructureItem, routeControlViewType?: ERouteControlViewType) => {
      infrastructureItemRef.current = geoItem || null;

      const from = {
        coordinates: centeredCoordinates,
        fixed: true,
      };

      const to = geoItem ? { coordinates: geoItem.coordinates } : undefined;

      mapRef.current?.startRouting(
        from,
        to,
        'properties.name === "main" && geometry.type === "Polygon"',
        undefined,
        routeControlViewType || viewType,
      );
    },
    [centeredCoordinates],
  );

  const startBuildingRoute = React.useCallback(
    (item?: IInfrastructureItem, routeControlViewType?: ERouteControlViewType) => {
      startRouting(item, routeControlViewType);

      trackRouteStart({
        profile: analyticsParams.profile,
        buttonType: item ? 'pin' : 'block',
        objectType: analyticsParams.objectType,
      });
    },
    [analyticsParams, startRouting],
  );

  return {
    startBuildingRoute,
    isRouteMode,
    handleRouteModeEnter,
    handleRouteModeExit,
    handleRouteSuccess,
  };
};
