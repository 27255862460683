import { createSlice } from '@reduxjs/toolkit';

import { TParamState } from 'shared/mf-infrastructure/types/params';

const initialState: TParamState | null = null;

/* istanbul ignore next */
export const paramsSlice = createSlice<TParamState | null, {}, 'params'>({
  initialState,
  name: 'params',
  reducers: {},
});
