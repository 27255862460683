import { createSlice } from '@reduxjs/toolkit';

import { TPageState } from 'shared/mf-infrastructure/types/page';

const initialState: TPageState = {
  subdomain: 'www',
  isMobileView: false,
};

/* istanbul ignore next */
export const pageSlice = createSlice({
  initialState,
  name: 'page',
  reducers: {},
});
