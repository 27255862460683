import { IInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

interface ITrackBuildRouteSuccessParams {
  blockType: string;
  objectType: string | null;
  regularInfrastructureItem: IInfrastructureItem | null;
  isTransportAccessibilityServiceOpened: boolean;
  profile: string | null;
}

export function trackRouteSuccess({
  blockType,
  objectType,
  regularInfrastructureItem,
  isTransportAccessibilityServiceOpened,
  profile,
}: ITrackBuildRouteSuccessParams) {
  const isFutureObject = regularInfrastructureItem ? 'constructionYearFrom' in regularInfrastructureItem : false;
  const isObjectPoint = isTransportAccessibilityServiceOpened || !!regularInfrastructureItem;
  const buttonType = getButtonType(regularInfrastructureItem, isTransportAccessibilityServiceOpened);

  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Infrastructure',
    label: 'build_route',
    name: 'oldevent',
    page: {
      extra: {
        profile,
        button_type: buttonType,
        block_type: blockType,
        is_future_object: isFutureObject,
        type_in: !isObjectPoint,
        object_type: objectType,
      },
    },
    useLastProducts: true,
  });
}

function getButtonType(
  regularInfrastructureItem: IInfrastructureItem | null,
  isTransportAccessibilityServiceOpened: boolean,
) {
  if (isTransportAccessibilityServiceOpened) {
    return 'index_pin';
  }

  if (regularInfrastructureItem) {
    return 'pin';
  }

  return 'block';
}
