import { useCallback, useEffect, useState } from 'react';

import { GLOBAL_EVENTS } from 'shared/mf-infrastructure/constants/globalEvents';
import { EMapService } from 'shared/mf-infrastructure/types/map';

export const useSelectedMapService = (serviceTypes: EMapService[]) => {
  const [mapService, setMapService] = useState<EMapService | null>(null);

  const transportAccessibilityOpener = useCallback(() => setMapService(EMapService.TransportAccessibility), []);

  useEffect(() => {
    if (!serviceTypes.includes(EMapService.TransportAccessibility)) {
      return;
    }

    document.addEventListener(GLOBAL_EVENTS.mapServices.openTransportAccessibility, transportAccessibilityOpener);

    return () => {
      document.removeEventListener(GLOBAL_EVENTS.mapServices.openTransportAccessibility, transportAccessibilityOpener);
    };
  }, [serviceTypes, transportAccessibilityOpener]);

  return [mapService, setMapService] as const;
};
