import * as React from 'react';

import * as style from './InfrastructureLayout.css';

interface IInfrastructureLayoutProps {
  isMobile?: boolean;
  children: React.ReactNode;
}

export const InfrastructureLayout: React.FC<IInfrastructureLayoutProps> = ({ children }) => {
  return (
    <div className={style['container']}>
      <div className={style['wrapper']}>{children}</div>
    </div>
  );
};
