import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsPanorama } from 'shared/mf-infrastructure/selectors/panorama';
import { panoramaActions } from 'shared/mf-infrastructure/slices';
import { EPanoramaStatus } from 'shared/mf-infrastructure/types/panorama';

import { IAnalyticsParams } from './useAnalyticsParams';
import { trackPanoramaClose, trackPanoramaOpen } from '../tracking';

interface IUsePanoramaProps {
  isPanorama: boolean;
  handlePanoramaSuccessEnter: () => void;
  handleNoPanoramaEnter: () => void;
  handlePanoramaExit: () => void;
}

export const usePanoramaProps = (analyticsParams: IAnalyticsParams): IUsePanoramaProps => {
  const isPanorama = useSelector(selectIsPanorama);
  const dispatch = useDispatch();

  const enterAnalyticsParamsRef = React.useRef<IAnalyticsParams>();

  const sendEnterAnalytics = React.useCallback((analyticsParams: IAnalyticsParams) => {
    const { objectType, blockType, profile } = analyticsParams;
    trackPanoramaOpen({ objectType, blockType, profile });
    enterAnalyticsParamsRef.current = analyticsParams;
  }, []);

  const handlePanoramaSuccessEnter = React.useCallback(() => {
    dispatch(panoramaActions.setStatus(EPanoramaStatus.Loaded));
    sendEnterAnalytics(analyticsParams);
  }, [analyticsParams, dispatch, sendEnterAnalytics]);

  const handleNoPanoramaEnter = React.useCallback(() => {
    dispatch(panoramaActions.setStatus(EPanoramaStatus.None));
    sendEnterAnalytics(analyticsParams);
  }, [analyticsParams, dispatch, sendEnterAnalytics]);

  const handlePanoramaExit = React.useCallback(() => {
    dispatch(panoramaActions.setStatus(null));

    const objectType = enterAnalyticsParamsRef.current?.objectType || '';
    const blockType = enterAnalyticsParamsRef.current?.blockType || '';
    const profile = enterAnalyticsParamsRef.current?.profile || null;

    trackPanoramaClose({ objectType, blockType, profile });
    enterAnalyticsParamsRef.current = undefined;
  }, [dispatch]);

  return {
    isPanorama,
    handlePanoramaSuccessEnter,
    handleNoPanoramaEnter,
    handlePanoramaExit,
  };
};
