import { createSelector } from '@reduxjs/toolkit';

import { selectSimilarNewbuildingsApiResult } from './selectSimilarNewbuildingsApiResult';

export const selectSimilarNewbuildingsModelVersion = createSelector(
  selectSimilarNewbuildingsApiResult,
  (result): string | undefined => {
    return result?.modelVersion;
  },
);
