import { UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import { IMapRowsProps, MapRows } from 'shared/mf-infrastructure/components/MapRows';

import * as styles from './InfrastructureTypeRows.css';

export type TIInfrastructureTypeRowsProps = {
  clickableRows: IMapRowsProps['items'];
  nonClickableRows: IMapRowsProps['items'];
  onRowClick: (rowId: string) => void;
  selectedItemId?: string;
};

export const InfrastructureTypeRows: React.FC<TIInfrastructureTypeRowsProps> = ({
  clickableRows,
  nonClickableRows,
  onRowClick,
  selectedItemId,
}) => {
  return (
    <div>
      <div className={styles['infrastructure-rows']} data-testid="ClickableInfrastructureRows">
        <MapRows items={clickableRows} onRowClick={onRowClick} selectedItemId={selectedItemId} />
      </div>

      {nonClickableRows.length > 0 && (
        <div className={styles['infrastructure-rows']} data-testid="NonClickableInfrastructureRows">
          <div className={styles['non-clickable-heading-wrapper']}>
            <UIHeading4>Отсутствует</UIHeading4>
          </div>
          <MapRows items={nonClickableRows} hasHover={false} />
        </div>
      )}
    </div>
  );
};
