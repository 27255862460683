export const GLOBAL_EVENTS = {
  mapServices: {
    // Требование от потребителя мкф открыть сервис транспортной доступности.
    openTransportAccessibility: 'map-services:open-transport-accessibility:v1',
    // Требование от потребителя мкф открыть мкф с активным типом.
    openInfrastructureType: 'map-services:open-infrastructure-type:v1',
    // Сигнал потребителю о доступных сервисах карты.
    available: 'map-services:available:v1',
  },
};
