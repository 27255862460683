import { IInfrastructureData } from 'shared/mf-infrastructure/types/infrastructure';
import { ITAInfrastructureData, TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';

import { EMapService } from '../types/map';

export const findGeoObjectDatum = (
  geoObjectId: string,
  regularInfrastructureByYear: Map<number, IInfrastructureData>,
  transportAccessibilityInfrastructure: Map<TTAInfrastructureType, ITAInfrastructureData>,
  mapService: EMapService | null,
) => {
  if (mapService === EMapService.TransportAccessibility) {
    return findTAGeoObjectDatum(geoObjectId, transportAccessibilityInfrastructure);
  }

  return findRegularGeoObjectDatum(geoObjectId, regularInfrastructureByYear);
};

function findRegularGeoObjectDatum(geoObjectId: string, regularInfrastructureByYear: Map<number, IInfrastructureData>) {
  const yearInfrastructures = Array.from(regularInfrastructureByYear.values());

  for (const { geoObjectsData } of yearInfrastructures) {
    for (const datum of geoObjectsData) {
      if (datum.geoObject.options.id === geoObjectId) {
        return datum;
      }
    }
  }

  return null;
}

function findTAGeoObjectDatum(
  geoObjectId: string,
  transportAccessibilityInfrastructureByType: Map<TTAInfrastructureType, ITAInfrastructureData>,
) {
  const infrastructuresByTypes = Array.from(transportAccessibilityInfrastructureByType.values());

  for (const { geoObjectsData } of infrastructuresByTypes) {
    for (const datum of geoObjectsData) {
      if (datum.geoObject.options.id === geoObjectId) {
        return datum;
      }
    }
  }

  return null;
}
