import * as React from 'react';

import { IInfrastructureData } from '../../../types/infrastructure';
import { getNextAvailableYear } from '../../../utils/getNextAvailableYear';

export const useSelectedYear = (infrastructureMapByYearMap: Map<number, IInfrastructureData>) => {
  const [nextAvailableYear, setNextAvailableYear] = React.useState<number | null>(null);
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());

  const handleSelectYear = React.useCallback(
    (selectedYear: number) => {
      const nextYear = getNextAvailableYear(selectedYear, infrastructureMapByYearMap);

      setSelectedYear(selectedYear);
      setNextAvailableYear(nextYear);
    },
    [infrastructureMapByYearMap],
  );

  return { handleSelectYear, selectedYear, nextAvailableYear };
};
