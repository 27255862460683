import { IconChevronLeft12, IconChevronRight12 } from '@cian/ui-kit';
import cx from 'clsx';

import * as styles from './VerticalSmallArrow.css';

export interface IVerticalSmallArrowProps {
  direction: -1 | 1;
  offset?: number;
  containerClass?: string;
  positionTop?: string;
  onClick?(): void;
}

const DEFAULT_OFFSET = -14;
const DEFAULT_POSITION_TOP = '50%';

export function VerticalSmallArrow({
  direction,
  offset = DEFAULT_OFFSET,
  containerClass,
  onClick,
  positionTop = DEFAULT_POSITION_TOP,
}: IVerticalSmallArrowProps) {
  const ArrowIcon = direction < 0 ? IconChevronLeft12 : IconChevronRight12;
  const style = direction < 0 ? { left: `${offset}px`, top: positionTop } : { right: `${offset}px`, top: positionTop };

  return (
    <button className={cx(styles['arrow'], containerClass)} style={style} onClick={onClick}>
      <ArrowIcon color={'gray_icons_100'} />
    </button>
  );
}
