import { Map } from '@cian/frontend-newbuilding-map-component';
import { IGeoObject, IYGeoObjectsFetchData } from '@cian/frontend-newbuilding-map-component/es/types';
import { IControl } from '@cian/frontend-newbuilding-map-component/es/types/control';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { mapBoundsActions } from 'shared/mf-infrastructure/slices';
import { ICoordinates, IRoutingBuildSuccessParams } from 'shared/mf-infrastructure/types/map';

interface IMapContainerProps {
  centeredCoordinates: ICoordinates;
  geoObjectNames: string[];
  apiKey: string;
  suggestApiKey: string;
  onGeoObjectsFetch: (data: IYGeoObjectsFetchData) => Promise<IGeoObject[]>;
  onBalloonHtmlFetch: (geoObject: IGeoObject) => Promise<string>;
  controls: IControl[];
  id?: string;
  mapRef?: React.MutableRefObject<Map | null>;
  zoom?: number;
  suggestPosition?: 'top' | 'bottom';
  onRulerClick?: () => void;
  onMapLoaded?: () => void;
  onBalloonOpen?: (geoObject: IGeoObject) => void;
  onFullscreenEnter?: () => void;
  onFullscreenExit?: () => void;
  onZoomControlClick?: () => void;
  onRoutingStart?: () => void;
  onRoutingEnd?: () => void;
  onRouteBuildingSuccess?: (params: IRoutingBuildSuccessParams) => void;
  onNoPanoramaEnter?: () => void;
  onPanoramaExit?: () => void;
  onPanoramaSuccessEnter?: () => void;
  onRulerEnter?: () => void;
  onRulerExit?: () => void;
}

export const InfrastructureMapContainer: React.FC<IMapContainerProps> = ({
  centeredCoordinates,
  geoObjectNames,
  apiKey,
  suggestApiKey,
  onGeoObjectsFetch,
  onBalloonHtmlFetch,
  id = 'infrastructure-microfrontend-map',
  mapRef,
  onBalloonOpen,
  onMapLoaded,
  onFullscreenEnter,
  onFullscreenExit,
  onNoPanoramaEnter,
  onPanoramaExit,
  onPanoramaSuccessEnter,
  controls,
  onRoutingStart,
  onRoutingEnd,
  onRouteBuildingSuccess,
  onRulerEnter,
  onRulerExit,
  suggestPosition = 'bottom',
  zoom = 15,
}) => {
  const dispatch = useDispatch();
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);

  const handleMapStateUpdate = React.useCallback(
    (state: IYGeoObjectsFetchData) => {
      dispatch(mapBoundsActions.update(state));
    },
    [dispatch],
  );

  const setMapRef = React.useCallback(
    (node: Map | null) => {
      if (isMapLoaded && mapRef) {
        mapRef.current = node;
      }
    },
    [isMapLoaded, mapRef],
  );

  const handleMapLoaded = React.useCallback(() => {
    setIsMapLoaded(true);
    onMapLoaded?.();
  }, [onMapLoaded]);

  return (
    <Map
      ref={setMapRef}
      media={[
        {
          minWidth: -Infinity,
          maxWidth: Infinity,
          options: {
            center: centeredCoordinates,
            styles: { width: '100%', height: '100%' },
            animationDuration: 300,
            defaultZoom: zoom,
          },
        },
      ]}
      geoObjectNames={geoObjectNames}
      firstPaintOptions={{
        disableScrollZoom: true,
        displayRouteInfo: true,
        fetch: {
          onGeoObjectsFetch,
          onBalloonHtmlFetch,
        },
        id,
        styles: { width: '100%', height: '100%' },
        zoomRange: { min: 1, max: 17 },
        controls,
        apiKey,
        suggestApiKey,
        onBalloonOpen,
        onMapSuccess: handleMapLoaded,
        onFullscreenEnter,
        onFullscreenExit,
        onNoPanoramaEnter,
        onPanoramaExit,
        onPanoramaSuccessEnter,
        onRoutingStart,
        onRoutingEnd,
        onRouteBuildingSuccess,
        onRulerEnter,
        onMove: handleMapStateUpdate,
        onRulerExit,
        suggestPosition,
      }}
    />
  );
};
