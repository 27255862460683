import classNames from 'clsx';
import * as React from 'react';

import * as styles from './InfoPlate.css';

export interface IInfoPlateProps {
  content: React.ReactNode;
  icon: React.ReactNode;
  dataTestId?: string;
  withHorizontalMargin?: boolean;
  onClick?: () => void;
}

export const InfoPlate: React.FC<IInfoPlateProps> = ({ content, icon, withHorizontalMargin, onClick, dataTestId }) => {
  const containerClassName = classNames(
    styles['container'],
    withHorizontalMargin && styles['horizontal-margin'],
    onClick && styles['clickable'],
  );

  return (
    <div className={containerClassName} data-testid={dataTestId} onClick={onClick} role="button">
      <div className={styles['icon-wrapper']}>{icon}</div>
      {content}
    </div>
  );
};
