import { IFuture } from 'shared/mf-infrastructure/types/futureInfrastructure';

const MAX_CONSTRUCTION_PERIOD = 4;

export function getConstructionYears(item: IFuture) {
  if (item.constructionYearFrom === item.constructionYearTo) {
    return [item.constructionYearFrom];
  }

  const constructionYearTo = item.constructionYearTo || item.constructionYearFrom + MAX_CONSTRUCTION_PERIOD;

  const constructionYears = [];

  for (let year = item.constructionYearFrom; year <= constructionYearTo; year++) {
    constructionYears.push(year);
  }

  return constructionYears;
}
