import { createSelector } from '@reduxjs/toolkit';

import { transformSimilarNewbuildingsToInfrastructureItems } from 'shared/mf-infrastructure/mappers/transformGetRecommendationsNearNewbuildingResponse';
import { ISimilarNewbuildingItem } from 'shared/mf-infrastructure/types/infrastructure';

import { selectSimilarNewbuildingsApiResult } from './selectSimilarNewbuildingsApiResult';

export const selectSimilarNewbuildingsMap = createSelector(
  selectSimilarNewbuildingsApiResult,
  (result): Map<string, ISimilarNewbuildingItem> => {
    const map: Map<string, ISimilarNewbuildingItem> = new Map();

    if (result) {
      const items = transformSimilarNewbuildingsToInfrastructureItems(result);

      items.forEach(item => map.set(item.id, item));
    }

    return map;
  },
);
