import { IMapGeoObject, Map as NewbuildingMapComponent } from '@cian/frontend-newbuilding-map-component';
import { useCallback, useRef } from 'react';

import {
  POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES,
  TRANSPORT_ACCESSIBILITY_SIDEBAR_WIDTH,
} from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import { useWidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import { ICoordinates } from 'shared/mf-infrastructure/types/map';
import {
  EOutsideTAInfrastructureType,
  TTAInfrastructureItem,
} from 'shared/mf-infrastructure/types/transportAccessibility';
import { getRouteLabel } from 'shared/mf-infrastructure/utils/getRouteLabel';

import { getAnalyticsObjectType, trackRouteStart } from '../tracking';

export const useTransportAccessibilityRouting = (
  mapApi: NewbuildingMapComponent | null,
  mainPinCoordinates: ICoordinates,
) => {
  const routeIdRef = useRef<string>();
  const routeLabelRef = useRef<IMapGeoObject>();
  const { profile } = useWidgetSettingsContext();

  const setTARouting = useCallback(
    async (item: TTAInfrastructureItem) => {
      if (!mainPinCoordinates) {
        return;
      }

      const objectType = getAnalyticsObjectType(item.type);
      trackRouteStart({
        objectType,
        buttonType: 'index_pin',
        profile: profile || null,
      });

      const routeId = `customRoute-${item.id}`;
      routeIdRef.current = routeId;

      await mapApi?.addRoute(
        routeId,
        { coordinates: mainPinCoordinates },
        { coordinates: item.coordinates },
        'pedestrian',
        [0, 0, 0, TRANSPORT_ACCESSIBILITY_SIDEBAR_WIDTH],
      );

      if (POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES.includes(item.type as EOutsideTAInfrastructureType) && item.title) {
        const routeLabelGeoObject = getRouteLabel({
          id: `${routeId}-label`,
          coordinates: item.coordinates,
          text: item.title,
        });

        routeLabelRef.current = routeLabelGeoObject;
        mapApi?.addGeoObjects([routeLabelGeoObject]);
      }
    },
    [mapApi, mainPinCoordinates],
  );

  const resetTARouting = useCallback(() => {
    if (routeIdRef.current) {
      mapApi?.removeRoute(routeIdRef.current);
      routeIdRef.current = undefined;

      if (routeLabelRef.current) {
        mapApi?.removeGeoObjects([routeLabelRef.current], false);
        routeLabelRef.current = undefined;
      }
    }
  }, [mapApi]);

  return {
    setTARouting,
    resetTARouting,
  };
};
