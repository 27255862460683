import * as React from 'react';

import {
  InfrastructureTypeRows,
  NoInfrastructureOutside,
} from 'shared/mf-infrastructure/components/TransportAccessibility';
import { useWidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import {
  ETransportAccessibilityTab,
  ITAInfrastructure,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

import { getOutsideRows } from './helpers';
import { getAnalyticsBlockType, trackInfrastructureTypeClick } from '../InfrastructureContainer/tracking';

export interface ITransportAccessibilityOutsideProps {
  onTransportAccessibilityChange: (infrastructureType: TTAInfrastructureType | null) => void;
  rate: React.ReactNode;
  transportAccessibilityInfrastructure?: ITAInfrastructure;
}

export const TransportAccessibilityOutside: React.FC<ITransportAccessibilityOutsideProps> = ({
  onTransportAccessibilityChange,
  rate,
  transportAccessibilityInfrastructure,
}) => {
  const { profile } = useWidgetSettingsContext();

  const clickableRows = React.useMemo(
    () => getOutsideRows(transportAccessibilityInfrastructure),
    [transportAccessibilityInfrastructure],
  );

  const [selectedType, setSelectedType] = React.useState(clickableRows[0]?.id);

  React.useEffect(() => {
    onTransportAccessibilityChange(selectedType as TTAInfrastructureType);

    return () => onTransportAccessibilityChange(null);
  }, []);

  const handleRowClick = React.useCallback(
    (infrastructureType: TTAInfrastructureType) => {
      onTransportAccessibilityChange(infrastructureType);
      setSelectedType(infrastructureType);

      const blockType = getAnalyticsBlockType(ETransportAccessibilityTab.Outside, []);
      trackInfrastructureTypeClick({ infrastructureType, blockType, profile: profile || null });
    },
    [onTransportAccessibilityChange, profile],
  );

  return (
    <>
      {rate}
      {clickableRows.length > 0 ? (
        <InfrastructureTypeRows
          clickableRows={clickableRows}
          nonClickableRows={[]}
          onRowClick={handleRowClick}
          selectedItemId={selectedType}
        />
      ) : (
        <NoInfrastructureOutside />
      )}
    </>
  );
};
