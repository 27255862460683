import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import { InfrastructureLayout } from 'shared/mf-infrastructure/components/Infrastructure';

import * as styles from './InfrastructureLoader.css';

interface IInfrastructureLoaderProps {
  isMobile?: boolean;
}

export const InfrastructureLoader: React.FC<IInfrastructureLoaderProps> = ({ isMobile }) => {
  return (
    <InfrastructureLayout isMobile={isMobile}>
      <div className={styles['wrapper']}>
        <Spinner size={40} />
      </div>
    </InfrastructureLayout>
  );
};
