import { IInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';

import {
  POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES,
  POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES,
} from '../constants/transportAccessibilityInfrastructure';
import { TTAInfrastructureItem } from '../types/transportAccessibility';

export function isTransportAccessibilityInfrastructureItem(
  infrastructureItem: IInfrastructureItem | TTAInfrastructureItem,
) {
  const transportAccessibilityInfrastructureTypes = [
    ...POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES,
    ...POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES,
  ] as string[];

  return transportAccessibilityInfrastructureTypes.includes(infrastructureItem.type);
}
