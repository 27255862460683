import { UIText1 } from '@cian/ui-kit';
import classNames from 'clsx';
import * as React from 'react';

import * as styles from './InfrastructureFromBuilder.css';
import { DepartmentLink } from '../../DepartmentLink';
import { InsideIcon } from '../../InsideIcon';
import { RateMark } from '../parts';

export interface IInfrastructureTerm {
  done: boolean;
  title: string;
}

export interface IInfrastructureFromBuilderProps {
  departmentLink: string;
  maxRate: string;
  onDepartmentLinkClick?: () => void;
  rate: string;
  terms: IInfrastructureTerm[];
}

export const InfrastructureFromBuilder: React.FC<IInfrastructureFromBuilderProps> = ({
  departmentLink,
  maxRate,
  onDepartmentLinkClick,
  rate,
  terms,
}) => {
  return (
    <>
      <div className={styles['department-link-wrapper']}>
        <DepartmentLink href={departmentLink} onClick={onDepartmentLinkClick} size="large" />
      </div>
      <div className={styles['rate-mark-wrapper']}>
        <RateMark icon={<InsideIcon />} maxRate={maxRate} rate={rate} />
      </div>
      <ul className={styles['terms']} data-testid="InfrastructureFromBuilderTerms">
        {terms.map((term, idx) => (
          <li key={idx} className={classNames(styles['term-item'], term.done && styles['term-item--done'])}>
            <UIText1 color="current_color">{term.title}</UIText1>
          </li>
        ))}
      </ul>
    </>
  );
};
