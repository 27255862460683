import { IMapGeoObject } from '@cian/frontend-newbuilding-map-component';

import { IGetInfrastructureV4ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV4ResponseSchema';
import {
  INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
  OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
} from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import {
  transformResultToMainGeoObjects,
  transformTransportAccessibilityInfrastructure,
} from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response';
import {
  EOutsideTAInfrastructureType,
  ITAInfrastructureData,
  ITAInfrastructureItem,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';
import { getGeoObject } from 'shared/mf-infrastructure/utils/getGeoObject';

import { getAvailableInsideInfrastructureTypes } from './getAvailableInsideInfrastructureTypes';
import { getAvailableOutsideInfrastructureTypes } from './getAvailableOutsideInfrastructureTypes';

export function getTransportAccessibilityInfrastructureMapByType(result: IGetInfrastructureV4ResponseSchema) {
  const transportAccessibilityInfrastructure = transformTransportAccessibilityInfrastructure(
    result.transportAccessibility,
  );

  const map = new Map<TTAInfrastructureType, ITAInfrastructureData>();

  if (!transportAccessibilityInfrastructure) {
    return map;
  }

  const mainObjects = transformResultToMainGeoObjects(result);

  const availableOutsideInfrastructure = getAvailableOutsideInfrastructureTypes(transportAccessibilityInfrastructure);

  availableOutsideInfrastructure.forEach((infrastructureType: EOutsideTAInfrastructureType) => {
    addMainItems(mainObjects, infrastructureType, map);

    const infrastructureItems =
      transportAccessibilityInfrastructure.outside[OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType]] ||
      [];

    infrastructureItems.forEach(item => addItemToMap(item, infrastructureType, map));
  });

  const { existingInfrastructureTypes } = getAvailableInsideInfrastructureTypes(transportAccessibilityInfrastructure);

  existingInfrastructureTypes.forEach(infrastructureType => {
    addMainItems(mainObjects, infrastructureType, map);

    const infrastructureItems =
      transportAccessibilityInfrastructure.inside[INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType]] || [];

    infrastructureItems.forEach(item => addItemToMap(item, infrastructureType, map));
  });

  return map;
}

function addItemToMap(
  item: ITAInfrastructureItem,
  type: TTAInfrastructureType,
  map: Map<TTAInfrastructureType, ITAInfrastructureData>,
) {
  const { coordinates, id } = item;

  const geoObjectsData = map.get(type)?.geoObjectsData || [];

  const geoObject = getGeoObject({ type, coordinates, id });

  geoObjectsData.push({
    geoObject,
    infrastructureItem: item,
  });

  map.set(type, { geoObjectsData });

  return map;
}

function addMainItems(
  mainObjects: IMapGeoObject[],
  infrastructureType: TTAInfrastructureType,
  map: Map<TTAInfrastructureType, ITAInfrastructureData>,
) {
  const geoObjectsData = map.get(infrastructureType)?.geoObjectsData || [];

  mainObjects.forEach(mainObject => {
    geoObjectsData.push({ geoObject: mainObject, infrastructureItem: null });
  });

  map.set(infrastructureType, { geoObjectsData });
}
