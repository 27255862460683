import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  InfrastructureError,
  InfrastructureLoader,
  InfrastructureWrapper,
} from 'shared/mf-infrastructure/components/Infrastructure';
import { NEWBUILDING_CARD_PROFILE } from 'shared/mf-infrastructure/constants/profiles';
import { useAppDispatch } from 'shared/mf-infrastructure/hooks';
import { selectInfrastructureStatus } from 'shared/mf-infrastructure/selectors/infrastructure';
import { selectIsMobileView } from 'shared/mf-infrastructure/selectors/page';
import { selectParams } from 'shared/mf-infrastructure/selectors/params/selectParams';
import { loadInfrastructure } from 'shared/mf-infrastructure/thunks';

import { InfrastructureDesktopContainer } from './InfrastructureDesktopContainer';
import { InfrastructureMobileContainer } from './InfrastructureMobileContainer';
import { useInfrastructureProps } from './hooks';
import { useInfrastructureTypeService } from '../MapServices/hooks';

export function InfrastructureContainer() {
  const params = useSelector(selectParams);
  const props = useInfrastructureProps();
  const isMobile = useSelector(selectIsMobileView);
  useInfrastructureTypeService({ isMobile });
  const dispatch = useAppDispatch();
  const infrastructureStatus = useSelector(selectInfrastructureStatus);

  const retry = useCallback(() => {
    if (params !== null) {
      dispatch(loadInfrastructure());
    }
  }, [params, dispatch]);

  const content = useMemo(() => {
    if (!props || infrastructureStatus === 'pending' || infrastructureStatus === 'idle') {
      return <InfrastructureLoader isMobile={isMobile} />;
    }

    if (infrastructureStatus === 'rejected') {
      return <InfrastructureError retry={retry} isMobile={isMobile} />;
    }

    if (isMobile) {
      return <InfrastructureMobileContainer {...props} />;
    }

    return <InfrastructureDesktopContainer {...props} />;
  }, [infrastructureStatus, isMobile, props, retry]);

  const handleLoadInfrastructure = useCallback(() => {
    if (params !== null && infrastructureStatus === 'idle') {
      dispatch(loadInfrastructure());
    }
  }, [dispatch, infrastructureStatus, params]);

  const profile = params?.profile;

  useEffect(() => {
    if (profile === NEWBUILDING_CARD_PROFILE) {
      handleLoadInfrastructure();
    }
  }, [profile]);

  return (
    <ActionAfterViewObserver callback={handleLoadInfrastructure}>
      <InfrastructureWrapper>{content}</InfrastructureWrapper>
    </ActionAfterViewObserver>
  );
}
