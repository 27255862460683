import { useCallback, useEffect } from 'react';

import { GLOBAL_EVENTS } from 'shared/mf-infrastructure/constants/globalEvents';
import { useAppDispatch } from 'shared/mf-infrastructure/hooks';
import { fullscreenActions, infrastructureActions } from 'shared/mf-infrastructure/slices';
import { TRegularInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';

interface IUseInfrastructureTypeServiceProps {
  isMobile: boolean;
}

export const useInfrastructureTypeService = ({ isMobile = false }: IUseInfrastructureTypeServiceProps): void => {
  const dispatch = useAppDispatch();

  const infrastructureTypeOpener = useCallback(
    (event: CustomEvent<{ type: TRegularInfrastructureType }>) => {
      if (event.detail) {
        dispatch(infrastructureActions.setTypes({ activeTypes: [event.detail.type], checkedType: event.detail.type }));
      }

      if (isMobile) {
        dispatch(fullscreenActions.setFullscreenOpen());
      }
    },
    [dispatch, isMobile],
  );

  useEffect(() => {
    document.addEventListener(
      GLOBAL_EVENTS.mapServices.openInfrastructureType,
      infrastructureTypeOpener as EventListener,
    );

    return () => {
      document.removeEventListener(
        GLOBAL_EVENTS.mapServices.openInfrastructureType,
        infrastructureTypeOpener as EventListener,
      );
    };
  }, [infrastructureTypeOpener]);
};
