import { Image } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { MapPin } from '../MapPin';

interface IMobilePreviewProps {
  onClick: () => void;
  previewSrc: string;
}

export const MobilePreview: React.FC<IMobilePreviewProps> = ({ onClick, previewSrc }) => {
  return (
    <div data-testid="mobile-preview" className={style['map']} onClick={onClick}>
      <div className={style['img']}>
        <Image width="100%" height="100%" src={previewSrc} borderRadius="4px" objectFit="cover" alt="map" />
      </div>

      <div className={style['pin']}>
        <MapPin type="main" />
      </div>
    </div>
  );
};
