import { ITransportAccessibilityInsideWithOrderSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/TransportAccessibilityInsideWithOrderSchema';
import { ITransportAccessibilityOutsideWithOrderSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/TransportAccessibilityOutsideWithOrderSchema';
import {
  EInsideTAInfrastructureType,
  EOutsideTAInfrastructureType,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

export const POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES = Object.values(EOutsideTAInfrastructureType);
export const POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES = Object.values(EInsideTAInfrastructureType);

export const OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP: Record<
  EOutsideTAInfrastructureType,
  keyof Omit<ITransportAccessibilityOutsideWithOrderSchema, 'order' | 'title'>
> = {
  [EOutsideTAInfrastructureType.busStops]: 'busStops',
  [EOutsideTAInfrastructureType.tramStops]: 'tramStops',
  [EOutsideTAInfrastructureType.metro]: 'metro',
  [EOutsideTAInfrastructureType.parking]: 'parking',
  [EOutsideTAInfrastructureType.bikeParking]: 'bikeParking',
  [EOutsideTAInfrastructureType.chargingStations]: 'chargingStations',
  [EOutsideTAInfrastructureType.scooterParking]: 'scooterParking',
};

export const INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP: Record<
  EInsideTAInfrastructureType,
  keyof Omit<ITransportAccessibilityInsideWithOrderSchema, 'order' | 'title'>
> = {
  [EInsideTAInfrastructureType.yardWithoutCars]: 'yardWithoutCars',
  [EInsideTAInfrastructureType.parking]: 'parking',
  [EInsideTAInfrastructureType.dropOffPickUpArea]: 'dropOffPickUpArea',
  [EInsideTAInfrastructureType.bikeParking]: 'bikeParking',
  [EInsideTAInfrastructureType.chargingStations]: 'chargingStations',
  [EInsideTAInfrastructureType.scooterParking]: 'scooterParking',
  [EInsideTAInfrastructureType.boulevards]: 'boulevards',
  [EInsideTAInfrastructureType.trafficSafetyElements]: 'trafficSafetyElements',
};

export const INFRASTRUCTURE_TYPE_TO_TITLE_MAP: Record<TTAInfrastructureType, string> = {
  [EOutsideTAInfrastructureType.busStops]: 'Автобусная остановка',
  [EOutsideTAInfrastructureType.tramStops]: 'Трамвайная остановка',
  [EOutsideTAInfrastructureType.metro]: 'Метро',
  [EOutsideTAInfrastructureType.parking]: 'Парковка',
  [EOutsideTAInfrastructureType.bikeParking]: 'Велопарковка',
  [EOutsideTAInfrastructureType.chargingStations]: 'Зарядка электромобилей',
  [EOutsideTAInfrastructureType.scooterParking]: 'Парковка самокатов',

  [EInsideTAInfrastructureType.yardWithoutCars]: 'Двор без машин',
  [EInsideTAInfrastructureType.parking]: 'Парковка',
  [EInsideTAInfrastructureType.dropOffPickUpArea]: 'Быстрая остановка',
  [EInsideTAInfrastructureType.bikeParking]: 'Велопарковка',
  [EInsideTAInfrastructureType.chargingStations]: 'Зарядка электромобилей',
  [EInsideTAInfrastructureType.scooterParking]: 'Парковка самокатов',
  [EInsideTAInfrastructureType.boulevards]: 'Пешеходный бульвар',
  [EInsideTAInfrastructureType.trafficSafetyElements]: 'Элемент безопасности движения',
};

export const TRANSPORT_ACCESSIBILITY_SIDEBAR_WIDTH = 338;

export const MODAL_Z_INDEX = 10001;
