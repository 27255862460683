import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

import { getAnalyticsTabName } from '../getAnalyticsTabName';

export function trackTabChange(tab: string) {
  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Infrastructure',
    label: getAnalyticsTabName(tab),
    name: 'oldevent',
    useLastProducts: true,
  });
}
