import { IconSuccess16, UIText1 } from '@cian/ui-kit';
import classNames from 'clsx';
import * as React from 'react';

import * as styles from './MapRows.css';

export interface IMapRowsProps {
  items: Array<{
    id: string;
    text: string;
    IconComponent: React.FunctionComponent;
  }>;
  onRowClick?: (rowId: string) => void;
  selectedItemId?: string;
  hasHover?: boolean;
}

/* istanbul ignore next */
export const MapRows: React.FC<IMapRowsProps> = ({ items, onRowClick, selectedItemId, hasHover = true }) => {
  return (
    <>
      {items.map(item => {
        const { IconComponent, id, text } = item;

        return (
          <button
            key={id}
            role="button"
            className={classNames(styles['row'], hasHover && styles['has-hover'])}
            onClick={() => onRowClick && onRowClick(id)}
            data-testid="MapRow"
          >
            <IconComponent />
            <div className={styles['text']}>
              <UIText1>{text}</UIText1>
              {selectedItemId === id && (
                <div className={styles['success-icon-wrapper']}>
                  <IconSuccess16 color="primary_100" />
                </div>
              )}
            </div>
          </button>
        );
      })}
    </>
  );
};
