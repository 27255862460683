import * as React from 'react';

import { IAnalyticsParams } from './useAnalyticsParams';
import { trackRulerClose, trackRulerOpen } from '../tracking';

interface IUseRulerProps {
  handleRulerEnter: () => void;
  handleRulerExit: () => void;
}

export const useRulerProps = (analyticsParams: IAnalyticsParams): IUseRulerProps => {
  const handleRulerEnter = React.useCallback(() => {
    const { objectType, blockType, profile } = analyticsParams;

    trackRulerOpen({ profile, objectType, blockType });
  }, [analyticsParams]);

  const handleRulerExit = React.useCallback(() => {
    const { objectType, blockType, profile } = analyticsParams;

    trackRulerClose({ objectType, blockType, profile });
  }, [analyticsParams]);

  return {
    handleRulerEnter,
    handleRulerExit,
  };
};
