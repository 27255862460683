import { createSlice } from '@reduxjs/toolkit';

import { IFullscreenState } from 'shared/mf-infrastructure/types/fullscreen';

const initialState: IFullscreenState = {
  isFullscreenOpen: false,
};

export const fullscreenSlice = createSlice({
  initialState,
  name: 'fullscreen',
  reducers: {
    setFullscreenOpen(state) {
      state.isFullscreenOpen = true;
    },
    setFullscreenClose(state) {
      state.isFullscreenOpen = false;
    },
  },
});

export const fullscreenActions = fullscreenSlice.actions;
