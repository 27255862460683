import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES } from 'shared/mf-infrastructure/constants/infrastructure';
import { loadInfrastructure } from 'shared/mf-infrastructure/thunks';
import { TInfrastructureState, ISetInfrastrutureTypesPayload } from 'shared/mf-infrastructure/types/infrastructure';

const initialState: TInfrastructureState = {
  activeTypes: ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES,
  status: 'idle',
  data: null,
  checkedType: null,
};

/* istanbul ignore next */
export const infrastructureSlice = createSlice({
  initialState,
  name: 'infrastructure',
  reducers: {
    setTypes: (state, action: PayloadAction<ISetInfrastrutureTypesPayload>) => {
      const { checkedType, activeTypes } = action.payload;
      state.activeTypes = activeTypes;
      state.checkedType = checkedType;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadInfrastructure.pending, state => {
        state.status = 'pending';
      })
      .addCase(loadInfrastructure.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.data = action.payload;
        state.activeTypes = [
          ...ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES,
          action.payload.similarOffersMapQs ? 'similar_objects' : action.payload.infrastructure.order[0],
        ];
      })
      .addCase(loadInfrastructure.rejected, state => {
        state.status = 'rejected';
      });
  },
});

export const infrastructureActions = infrastructureSlice.actions;
