import { exportsModule } from '@cian/mf-registry/browser';

import { createCustomElementConstructor } from './createCustomElementConstructor';
import { createApplicationContext } from '../services/applicationContext';
import { createApplicationState } from '../services/applicationState';

export function start() {
  const context = createApplicationContext();
  const store = createApplicationState(context);

  exportsModule({
    ui: {
      '/public/v1/get-infrastructure-microfrontend/': () => ({
        customElementConstructor: createCustomElementConstructor(context, store),
      }),
    },
  });
}
