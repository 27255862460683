import { TInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureItem } from 'shared/mf-infrastructure/types/transportAccessibility';

export function getIconBackgroundColor(infrastructureItem: TInfrastructureItem | TTAInfrastructureItem) {
  if ('line' in infrastructureItem && infrastructureItem.line?.color) {
    return infrastructureItem.line.color;
  }

  if ('constructionYearFrom' in infrastructureItem) {
    return '#3ac500';
  }

  return '#0468ff';
}
