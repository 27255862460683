import { IYGeoObjectsFetchData } from '@cian/frontend-newbuilding-map-component/es/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IYGeoObjectsFetchData = {
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 0,
  bbox: {
    topLeft: { lat: 0, lng: 0 },
    bottomRight: { lat: 0, lng: 0 },
  },
};

/* istanbul ignore next */
export const mapBoundsSlice = createSlice({
  initialState,
  name: 'mapBounds',
  reducers: {
    update: (state, action: PayloadAction<IYGeoObjectsFetchData>) => {
      state.bbox = action.payload.bbox;
      state.center = action.payload.center;
      state.zoom = action.payload.zoom;
    },
  },
});

export const mapBoundsActions = mapBoundsSlice.actions;
