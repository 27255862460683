import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import {
  IconBus16,
  IconChevronDown16,
  IconChevronUp16,
  IconRoute16,
  IconShow16,
  OnboardingTooltip,
  UIText1,
} from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import { EMapService } from 'shared/mf-infrastructure/types/map';

import * as styles from './MapServices.css';
import { MapRows } from '../MapRows';

const MAP_SERVICES = {
  [EMapService.FutureInfrastructure]: {
    text: 'Будущее района',
    IconComponent: IconShow16,
  },
  [EMapService.TransportAccessibility]: {
    text: 'Транспортная доступность',
    IconComponent: IconBus16,
  },
  [EMapService.Routes]: {
    text: 'Маршруты',
    IconComponent: IconRoute16,
  },
};

export interface IMapServicesProps {
  closeOnboarding: () => void;
  handleOnboardingShown: () => void;
  isOnboardingOpen: boolean;
  fixed: boolean;
  onServiceClick: (service: EMapService) => void;
  onToggle: (newOpen: boolean) => void;
  serviceTypes: EMapService[];
}

export const MapServices: React.FC<IMapServicesProps> = ({
  closeOnboarding,
  handleOnboardingShown,
  isOnboardingOpen,
  fixed,
  onServiceClick,
  onToggle,
  serviceTypes,
}) => {
  const [open, setOpen] = React.useState(true);

  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = contentRef.current?.scrollHeight + 'px';
    }
  }, [serviceTypes]);

  const handleTitleClick = React.useCallback(() => {
    if (!contentRef.current) {
      return;
    }

    setOpen(!open);

    if (!open) {
      contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
    } else {
      contentRef.current.style.maxHeight = '0';
    }

    onToggle(!open);
  }, [open, onToggle]);

  const items = serviceTypes.map(serviceType => ({
    id: serviceType,
    ...MAP_SERVICES[serviceType],
  }));

  const onboardingContent = (
    <ActionAfterViewObserver triggerOnce callback={handleOnboardingShown} percentVisible={1}>
      <span data-testid="MapOnboardingTooltipContent">Посмотрите, как похорошела транспортная доступность</span>
    </ActionAfterViewObserver>
  );

  return (
    <div className={clsx(styles['wrapper'], fixed && styles['fixed'])}>
      <OnboardingTooltip
        open={isOnboardingOpen}
        onClose={closeOnboarding}
        content={onboardingContent}
        placement="right"
        portal={false}
        theme="blue"
      >
        <div className={styles['container']}>
          <div className={styles['title-row']} onClick={handleTitleClick}>
            <UIText1 fontWeight="bold">Дополнительно</UIText1>
            <div className={styles['chevron-icon']}>{open ? <IconChevronDown16 /> : <IconChevronUp16 />}</div>
          </div>

          <div ref={contentRef} className={styles['content']} data-testid="MapServicesContent">
            <MapRows items={items} onRowClick={onServiceClick} />
          </div>
        </div>
      </OnboardingTooltip>
    </div>
  );
};
