import { Map as NewbuildingMapComponent } from '@cian/frontend-newbuilding-map-component';
import { useCallback } from 'react';

import { useTransportAccessibilityZoom } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useTransportAccessibilityZoom';
import { IInfrastructureData } from 'shared/mf-infrastructure/types/infrastructure';
import { ICoordinates } from 'shared/mf-infrastructure/types/map';
import { ITAInfrastructureData, TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';
import { toGeoObjects, toTransportAccessibilityGeoObjects } from 'shared/mf-infrastructure/utils/toGeoObjects';

interface IUseSetGeoObjectsCallbacksResult {
  /**
   * Устанавливает геообъекты обычной инфраструктуры по типам и году.
   */
  setRegularGeoObjects: (currentTypes: string[], selectedYear: number, onlyExisting: boolean) => void;
  /**
   * Устанавливает геообъекты сервиса транспортной доступности по типу.
   */
  setTransportAccessibilityGeoObjects: (infrastructureType: TTAInfrastructureType | null) => void;
}

/**
 * Возвращает функции установки геообъектов на карту.
 */
export const useSetGeoObjectsCallbacks = (
  mapApi: NewbuildingMapComponent | null,
  regularInfrastructureByYear: Map<number, IInfrastructureData>,
  transportAccessibilityInfrastructureByType: Map<TTAInfrastructureType, ITAInfrastructureData>,
  currentTypes: string[],
  selectedYear: number,
  pinCoordinates: ICoordinates,
  resetTARouting: () => void,
): IUseSetGeoObjectsCallbacksResult => {
  const { setTransportAccessibilityZoom } = useTransportAccessibilityZoom(mapApi, pinCoordinates);

  const setRegularGeoObjects = useCallback(
    (currentTypes: string[], selectedYear: number, onlyExisting: boolean) => {
      const geoObjects = toGeoObjects(regularInfrastructureByYear, selectedYear, onlyExisting);
      const geoObjectsByType = geoObjects.filter(item => currentTypes.includes(item.name));

      mapApi?.removeAllGeoObjects();
      mapApi?.addGeoObjects(geoObjectsByType);
    },
    [mapApi, regularInfrastructureByYear],
  );

  const setTransportAccessibilityGeoObjects = useCallback(
    async (infrastructureType: TTAInfrastructureType | null) => {
      if (infrastructureType) {
        const { isZoomChanged, animationDelay } = setTransportAccessibilityZoom(infrastructureType);

        if (isZoomChanged) {
          // Ждем окончания анимации изменения зума.
          await new Promise(resolve => setTimeout(resolve, animationDelay));
        }

        const geoObjects = toTransportAccessibilityGeoObjects(
          transportAccessibilityInfrastructureByType,
          infrastructureType,
        );

        mapApi?.removeAllGeoObjects();
        mapApi?.addGeoObjects(geoObjects);
      } else {
        setRegularGeoObjects(currentTypes, selectedYear, true);
      }

      resetTARouting();
    },
    [
      mapApi,
      transportAccessibilityInfrastructureByType,
      currentTypes,
      selectedYear,
      setRegularGeoObjects,
      setTransportAccessibilityZoom,
      resetTARouting,
    ],
  );

  return {
    setRegularGeoObjects,
    setTransportAccessibilityGeoObjects,
  };
};
