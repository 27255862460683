import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

interface ITrackRouteStartParams {
  objectType: string | null;
  buttonType: 'block' | 'pin' | 'index_pin';
  profile: string | null;
}

export function trackRouteStart({ profile, objectType, buttonType }: ITrackRouteStartParams) {
  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Infrastructure',
    label: 'route_button',
    name: 'oldevent',
    page: {
      extra: {
        profile,
        object_type: objectType,
        button_type: buttonType,
      },
    },
    useLastProducts: true,
  });
}
