import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NoInfrastructureOutside.css';

export const NoInfrastructureOutside: React.FC = () => {
  return (
    <div className={styles['wrapper']} data-testid="NoInfrastructureOutside">
      <UIText1 color="gray60_100">Рядом пока нет объектов транспортной доступности или они не загрузились</UIText1>
    </div>
  );
};
