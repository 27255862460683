import * as React from 'react';

import { MapServices } from 'shared/mf-infrastructure/components/MapServices';
import { useTransportAccessibilityOnboarding } from 'shared/mf-infrastructure/containers/MapServices/hooks';
import { EMapService } from 'shared/mf-infrastructure/types/map';

import { IAnalyticsParams } from '../InfrastructureContainer/hooks';
import {
  trackCollapseMapServices,
  trackExpandMapServices,
  trackMapServiceClick,
} from '../InfrastructureContainer/tracking';

export interface IMapServicesContainerProps {
  onServiceClick: (service: EMapService) => void;
  serviceTypes: EMapService[];
  fixed: boolean;
  analyticsParams: IAnalyticsParams;
}

export const MapServicesContainer: React.FC<IMapServicesContainerProps> = ({
  onServiceClick,
  serviceTypes,
  fixed,
  analyticsParams,
}) => {
  const { isOnboardingOpen, closeOnboarding, handleOnboardingShown } =
    useTransportAccessibilityOnboarding(serviceTypes);

  const handleServiceClick = React.useCallback(
    (serviceType: EMapService) => {
      onServiceClick(serviceType);

      if (serviceType) {
        trackMapServiceClick({ serviceType, objectType: analyticsParams.objectType, profile: analyticsParams.profile });
      }
    },
    [analyticsParams, onServiceClick],
  );

  const handleToggle = React.useCallback(
    (open: boolean) => {
      if (open) {
        trackExpandMapServices(analyticsParams.objectType, analyticsParams.profile);
      } else {
        trackCollapseMapServices(analyticsParams.objectType, analyticsParams.profile);
      }
    },
    [analyticsParams],
  );

  return (
    <MapServices
      closeOnboarding={closeOnboarding}
      handleOnboardingShown={handleOnboardingShown}
      isOnboardingOpen={isOnboardingOpen}
      onServiceClick={handleServiceClick}
      onToggle={handleToggle}
      serviceTypes={serviceTypes}
      fixed={fixed}
    />
  );
};
