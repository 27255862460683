import { createAsyncThunk } from '@reduxjs/toolkit';

import { Err } from 'shared/common/errors';
import { IGetRecommendationsNearNewbuildingResponse } from 'shared/common/repositories/newbuilding-search/v1/get-recommendations-near-newbuilding';
import { selectParams } from 'shared/mf-infrastructure/selectors/params/selectParams';
import { fetchSimilarNewbuildings } from 'shared/mf-infrastructure/services/fetchSimilarNewbuildings/fetchSimilarNewbuildings';
import { TParamState } from 'shared/mf-infrastructure/types/params';
import { IThunkApi } from 'shared/mf-infrastructure/types/redux';

export const loadSimilarNewbuildings = createAsyncThunk<
  IGetRecommendationsNearNewbuildingResponse | null,
  void,
  IThunkApi
>(
  'similarNewbuildings/loadData',
  async (_, thunkApi) => {
    const { rejectWithValue, extra, getState } = thunkApi;
    const state = getState();
    const params = selectParams(state);
    const newbuildingId = getNewbuildingIdParam(params);

    if (!newbuildingId) {
      return null;
    }

    const similarNewbuildingsResult = await fetchSimilarNewbuildings(extra, {
      newbuildingId,
    });

    if (Err.is(similarNewbuildingsResult)) {
      return rejectWithValue(similarNewbuildingsResult.Err);
    }

    return similarNewbuildingsResult.Ok;
  },
  {
    condition: (_, _api) => {
      const { getState } = _api;

      const state = getState();

      return state.similarNewbuildings.status !== 'pending';
    },
  },
);

function getNewbuildingIdParam(params: TParamState | null): number | null {
  if (params && 'newbuildingId' in params) {
    return params.newbuildingId;
  }

  return null;
}
