import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITransportAccessibilityState } from 'shared/mf-infrastructure/types/transportAccessibility/transportAccessibilityState';

const initialState: ITransportAccessibilityState = {
  activeTab: null,
};

/* istanbul ignore next */
export const transportAccessibilitySlice = createSlice({
  initialState,
  name: 'transportAccessibility',
  reducers: {
    setActiveTab: (state, action: PayloadAction<string | null>) => {
      state.activeTab = action.payload;
    },
  },
});

export const transportAccessibilityActions = transportAccessibilitySlice.actions;
