import { commarize } from 'shared/common/utils/number';
import { ISimilarObjectsItem } from 'shared/mf-infrastructure/types/infrastructure';

import * as styles from './styles.css';

export function getSimilarObjectsPinHtml(item: ISimilarObjectsItem): string {
  const price = commarize(item.offers.minPrice);
  const minPrice = item.offers.count > 1 ? `от ${price}` : price;

  return `<div class="${styles['pin']}">
      <div class="${styles['point']}"></div>
      <div class="${styles['balloon']}">
        ${
          item.offers.count > 1
            ? `<div class="${styles['count']}">${item.offers.count > 10 ? '9+' : item.offers.count}</div>`
            : ''
        }
        <div class="${styles['price']}">${minPrice}</div>
      </div>
    </div>`;
}
