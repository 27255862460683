import { EMapService } from 'shared/mf-infrastructure/types/map';

import { getAnalyticsTabName } from './getAnalyticsTabName';

export function getAnalyticsBlockType(
  activeTransportAccessibilityTab: string | null,
  serviceTypes: EMapService[],
): string {
  if (activeTransportAccessibilityTab) {
    return getAnalyticsTabName(activeTransportAccessibilityTab);
  }

  const hasTransportAccessibility = serviceTypes.includes(EMapService.TransportAccessibility);
  const hasFutureInfrastructure = serviceTypes.includes(EMapService.FutureInfrastructure);

  if (hasTransportAccessibility && hasFutureInfrastructure) {
    return 'future_plus_index';
  }

  if (hasFutureInfrastructure) {
    return 'future';
  }

  if (hasTransportAccessibility) {
    return 'index';
  }

  return 'standard';
}
