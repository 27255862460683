import { CustomTooltip, Radio } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './Year.css';

interface IYearProps {
  year: number;
  checked: boolean;
  nextAvailableYear: number | null;
  onChange: (value: number) => void;
}

export const Year: React.VFC<IYearProps> = ({ year, onChange, checked, nextAvailableYear }) => {
  const radioRef = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleChange = React.useCallback(() => {
    setOpen(false);
    onChange(year);
  }, [onChange, year]);

  const handleButtonClick = React.useCallback(() => {
    setOpen(false);
    onChange(nextAvailableYear as number);
  }, [onChange, nextAvailableYear]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [onChange]);

  React.useEffect(() => {
    setOpen(nextAvailableYear === year);
  }, [nextAvailableYear]);

  return (
    <React.Fragment>
      <div ref={radioRef} data-testid="Year">
        <Radio name={String(year)} label={year} value={String(year)} checked={checked} onChange={handleChange} />
      </div>
      <CustomTooltip
        anchorRef={radioRef}
        arrow
        closeButton
        portal={false}
        preventOverflow
        content={
          <div className={styles['content']}>
            Есть изменения в {nextAvailableYear} г.
            <button className={styles['button']} onClick={handleButtonClick}>
              Посмотреть
            </button>
          </div>
        }
        flip
        maxWidth={216}
        onClose={handleClose}
        open={open}
        placement="bottom"
        size="S"
        theme="black"
      />
    </React.Fragment>
  );
};
