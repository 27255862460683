import { ITransportAccessibilityDetail } from 'shared/mf-infrastructure/types/transportAccessibilityTypes';

import { prepareRate } from './prepareRate';
import { ITransportTerm } from '../../../components/TransportAccessibilityRate';
import { MAX_TRANSPORT_ACCESSIBILITY_RATE } from '../../../constants/transportAccessibility';

export const prepareTransportAccessibility = (terms: ITransportAccessibilityDetail[]): ITransportTerm[] => {
  return terms.map(({ rate, title }) => {
    const rateText = prepareRate(rate);
    const rateLabel = `${title} ${rateText}`;
    const rateIndex = rate / MAX_TRANSPORT_ACCESSIBILITY_RATE;

    return {
      title,
      rateIndex,
      rateLabel,
      rateText,
    };
  });
};
