import * as React from 'react';

import * as style from './style.css';

interface IMobileModalLayoutProps {
  children: React.ReactNode;
}

export const MobileModalLayout: React.FC<IMobileModalLayoutProps> = ({ children }) => {
  return (
    <div data-testid="mobile-fullscreen" className={style['modal']}>
      {children}
    </div>
  );
};
