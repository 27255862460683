import { IGetInfrastructureV4ResponseSchema } from 'shared/common/repositories/infrastructure-caching/entities/get_infrastructure/GetInfrastructureV4ResponseSchema';
import { transformFutureRoads } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformFutureRoads';
import { transformTransportAccessibilityInfrastructure } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformTransportAccessibilityInfrastructure';
import {
  IInfrastructure,
  TInfrastructureItem,
  TRegularInfrastructureType,
} from 'shared/mf-infrastructure/types/infrastructure';

export const transformNewbuildingInfrastructure = (result: IGetInfrastructureV4ResponseSchema): IInfrastructure => {
  const transportAccessibilityInfrastructure = transformTransportAccessibilityInfrastructure(
    result.transportAccessibility,
  );

  const existingInfrastructure = result.infrastructure.items.map(({ type, ...rest }) => ({
    ...rest,
    type: type as TRegularInfrastructureType,
  }));
  const futureInfrastructure = result.future?.infrastructure || [];
  const futureRoads = transformFutureRoads(result.future?.roads);

  const infrastructure: TInfrastructureItem[] = [...existingInfrastructure, ...futureInfrastructure, ...futureRoads];

  return {
    regularInfrastructureItems: infrastructure,
    transportAccessibilityInfrastructure,
  };
};
