import { createSelector } from '@reduxjs/toolkit';

import { selectSimilarNewbuildingsApiResult } from './selectSimilarNewbuildingsApiResult';

export const selectSimilarNewbuildingIds = createSelector(selectSimilarNewbuildingsApiResult, (result): number[] => {
  if (result) {
    return result.newbuildings.map(item => item.id);
  }

  return [];
});
