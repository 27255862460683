import { IBBox } from '@cian/frontend-newbuilding-map-component/es/types';

export function transformBboxToPolygon(bbox: IBBox): string {
  const bounds = [
    [bbox.topLeft.lng, bbox.topLeft.lat],
    [bbox.bottomRight.lng, bbox.bottomRight.lat],
  ];

  return `${bounds[0][1].toFixed(6)}_${bounds[0][0].toFixed(6)},${bounds[1][1].toFixed(6)}_${bounds[0][0].toFixed(
    6,
  )},${bounds[1][1].toFixed(6)}_${bounds[1][0].toFixed(6)},${bounds[0][1].toFixed(6)}_${bounds[1][0].toFixed(
    6,
  )},${bounds[0][1].toFixed(6)}_${bounds[0][0].toFixed(6)}`;
}
