import { ISimilarNewbuildingItem } from 'shared/mf-infrastructure/types/infrastructure';

import * as styles from './styles.css';

interface IMapSimilarNewbuildingBalloonProps {
  item: ISimilarNewbuildingItem;
}

export function MapSimilarNewbuildingBalloon(props: IMapSimilarNewbuildingBalloonProps) {
  const {
    item: { id, imageUrl, minPrice, name, roomsSquareMeter, url },
  } = props;

  return (
    <div id={id} className={styles['balloon']}>
      {imageUrl && (
        <a
          className={styles['icon']}
          target={'_blank'}
          rel="noreferrer"
          href={url}
          title={name}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      <div className={styles['content']}>
        <a className={styles['title']} target={'_blank'} rel="noreferrer" href={url}>
          {name}
        </a>
        {roomsSquareMeter && minPrice && (
          <div>
            <span className={styles['min-price']}>{minPrice}</span>
            <span className={styles['roominess']}>{roomsSquareMeter}</span>
          </div>
        )}
      </div>
    </div>
  );
}
