import { IApplicationContext } from 'shared/mf-infrastructure/types/applicationContext';
import { TApplicationState, TReduxStore } from 'shared/mf-infrastructure/types/redux';
import { createReduxStore } from 'shared/mf-infrastructure/utils/redux';

export function createApplicationState(context: IApplicationContext): TReduxStore {
  const { config } = context;

  const initialState = config.getStrict<TApplicationState>('initialState');

  return createReduxStore(context, initialState, true);
}
