import { TRoutingMode, IRouteData, IWayPointData } from '@cian/frontend-newbuilding-map-component';

export enum EPanoramaState {
  Loaded,
  None,
}

export interface ICoordinates {
  /** Широта **/
  lat: number;
  /** Долгота **/
  lng: number;
}

export interface IRoutingBuildSuccessParams {
  mode: TRoutingMode;
  routes: IRouteData[];
  wayPoints: IWayPointData[];
}

export enum EMapService {
  TransportAccessibility = 'transportAccessibility',
  FutureInfrastructure = 'futureInfrastructure',
  Routes = 'routes',
}
