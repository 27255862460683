import { useMemo } from 'react';

import { ITransportTerm } from 'shared/mf-infrastructure/components/TransportAccessibility';
import { MAX_TRANSPORT_ACCESSIBILITY_RATE } from 'shared/mf-infrastructure/constants/transportAccessibility';
import {
  prepareRate,
  prepareTransportAccessibility,
} from 'shared/mf-infrastructure/containers/TransportAccessibilitySidebar/helpers';
import {
  IInfrastructureFromBuilderDetail,
  ITransportAccessibilityDetail,
  ITransportAccessibilityRate,
} from 'shared/mf-infrastructure/types/transportAccessibilityTypes';

interface IReportInfo {
  rate: number;
  displayRate: string;
  displayMaxRate: string;
  title: string;
  rateDetails: IInfrastructureFromBuilderDetail[] | ITransportTerm[];
}

export function useReportInfo(
  transportAccessibilityRate: ITransportAccessibilityRate | null,
  inside: boolean = false,
): IReportInfo | null {
  return useMemo(() => {
    const displayMaxRate = String(MAX_TRANSPORT_ACCESSIBILITY_RATE);
    const source = inside ? transportAccessibilityRate?.infrastructure : transportAccessibilityRate?.transport;
    const rate = source?.rate ?? null;
    const rateDetails = source?.rateDetails ?? null;
    const title = source?.title ?? null;
    const displayRate = source?.rate ? prepareRate(source.rate) : null;

    if (rate === null || rateDetails === null || title === null || displayRate === null) {
      return null;
    }

    return {
      rate,
      rateDetails: inside
        ? (rateDetails as IInfrastructureFromBuilderDetail[])
        : prepareTransportAccessibility(rateDetails as ITransportAccessibilityDetail[]),
      title,
      displayRate,
      displayMaxRate,
    };
  }, [transportAccessibilityRate, inside]);
}
