import { useCallback, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useSelector } from 'react-redux';

import { MapSimilarNewbuildingBalloon } from 'shared/mf-infrastructure/components/Infrastructure/parts/MapSimilarNewbuildingBalloon';
import { selectSimilarNewbuildingsMap } from 'shared/mf-infrastructure/selectors/similarNewbuildings';
import { ISimilarNewbuildingItem } from 'shared/mf-infrastructure/types/infrastructure';

import { trackSimilarNewbuildingLinkClick } from '../tracking';

export function useSimilarNewbuildingsBalloonRenderer() {
  const similarNewbuildingsMap = useSelector(selectSimilarNewbuildingsMap);

  const renderSimilarNewbuildingsBalloon = useCallback(
    (id: string) => {
      const item = similarNewbuildingsMap.get(id);

      if (!item) {
        return Promise.reject(new Error('Неверный тип пина!'));
      }

      return Promise.resolve(renderToStaticMarkup(<MapSimilarNewbuildingBalloon item={item} />));
    },
    [similarNewbuildingsMap],
  );

  const onSimilarNewbuildingsBalloonOpen = useCallback(
    (id: string) => {
      const element = document.getElementById(id);

      if (!element) {
        return;
      }

      element.addEventListener('click', event => {
        if (event.target instanceof HTMLAnchorElement) {
          const item = similarNewbuildingsMap.get(id) as ISimilarNewbuildingItem;
          trackSimilarNewbuildingLinkClick(item);
        }
      });
    },
    [similarNewbuildingsMap],
  );

  return useMemo(
    () => ({
      renderSimilarNewbuildingsBalloon,
      onSimilarNewbuildingsBalloonOpen,
    }),
    [onSimilarNewbuildingsBalloonOpen, renderSimilarNewbuildingsBalloon],
  );
}
