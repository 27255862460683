import { ITabsProps } from '@cian/ui-kit/prev';

import { ETransportAccessibilityTab } from 'shared/mf-infrastructure/types/transportAccessibility';
import { ITransportAccessibilityRate } from 'shared/mf-infrastructure/types/transportAccessibilityTypes';

export const useTransportAccessibilityTabs = (transportAccessibilityRate: ITransportAccessibilityRate | null) => {
  const transportAccessibility = transportAccessibilityRate?.transport || null;
  const infrastructureFromBuilder = transportAccessibilityRate?.infrastructure || null;

  const tabs: ITabsProps['tabs'] = [];

  if (transportAccessibility) {
    tabs.push({
      value: ETransportAccessibilityTab.Outside,
      title: 'Рядом с ЖК',
    });
  }

  if (infrastructureFromBuilder) {
    tabs.push({
      value: ETransportAccessibilityTab.Inside,
      title: 'Внутри ЖК',
    });
  }

  return tabs;
};
