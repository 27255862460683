import { IconBike16, IconBus16, IconCompetitor16, IconMetroMsk16, IconParking16 } from '@cian/ui-kit';

import { IconScooter16 } from 'shared/mf-infrastructure/components/Icons/IconScooter16';
import { IconStreetCar16 } from 'shared/mf-infrastructure/components/Icons/IconStreetCar16';
import { IMapRowsProps } from 'shared/mf-infrastructure/components/MapRows';
import { EOutsideTAInfrastructureType, ITAInfrastructure } from 'shared/mf-infrastructure/types/transportAccessibility';
import { getAvailableOutsideInfrastructureTypes } from 'shared/mf-infrastructure/utils/getAvailableOutsideInfrastructureTypes';

const INFRASTRUCTURE_TYPES: Record<
  EOutsideTAInfrastructureType,
  {
    text: string;
    IconComponent: React.FunctionComponent;
  }
> = {
  [EOutsideTAInfrastructureType.busStops]: {
    text: 'Автобусы',
    IconComponent: IconBus16,
  },
  [EOutsideTAInfrastructureType.tramStops]: {
    text: 'Трамваи',
    IconComponent: IconStreetCar16,
  },
  [EOutsideTAInfrastructureType.metro]: {
    text: 'Метро',
    IconComponent: IconMetroMsk16,
  },
  [EOutsideTAInfrastructureType.parking]: {
    text: 'Парковки',
    IconComponent: IconParking16,
  },
  [EOutsideTAInfrastructureType.bikeParking]: {
    text: 'Велопарковки',
    IconComponent: IconBike16,
  },
  [EOutsideTAInfrastructureType.chargingStations]: {
    text: 'Зарядки электромобилей',
    IconComponent: IconCompetitor16,
  },
  [EOutsideTAInfrastructureType.scooterParking]: {
    text: 'Парковка самокатов',
    IconComponent: IconScooter16,
  },
};

export const getOutsideRows = (
  transportAccessibilityInfrastructure: ITAInfrastructure | undefined,
): IMapRowsProps['items'] => {
  const availableInfrastructureTypes = getAvailableOutsideInfrastructureTypes(transportAccessibilityInfrastructure);

  return availableInfrastructureTypes.map(type => ({
    id: type,
    ...INFRASTRUCTURE_TYPES[type],
  }));
};
