import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

export function trackFullscreenOpen(blockType: string, profile: string | null) {
  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Infrastructure',
    label: 'fullscreen',
    name: 'oldevent',
    page: {
      extra: {
        profile,
        block_type: blockType,
      },
    },
    useLastProducts: true,
  });
}
