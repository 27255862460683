import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { ModalFullscreen } from '@cian/ui-kit';
import * as React from 'react';

import { VIEWED_TIMEOUT } from 'shared/mf-infrastructure/constants/viewedBlock';
import { IInfrastructureProps } from 'shared/mf-infrastructure/containers/InfrastructureContainer/types';
import { ITAInfrastructureData, TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';
import { getYandexMapPreview } from 'shared/mf-infrastructure/utils/getYandexMapPreview';
import { toGeoObjects } from 'shared/mf-infrastructure/utils/toGeoObjects';

import { useAnalyticsParams, useFullscreenProps, useGeoObjectBalloon, useRouteButtonProps } from './hooks';
import { InfrastructureMapContainer, InfrastructureMapTypes } from './parts';
import { trackInfrastructureShow } from './tracking';
import {
  InfrastructureLayout,
  RouteButton,
  MobilePreview,
  CloseModalButton,
  MobileModalLayout,
} from '../../components/Infrastructure';

export const InfrastructureMobileContainer: React.FC<IInfrastructureProps> = ({
  apiKey,
  suggestApiKey,
  mapRef,
  isMapLoaded,
  currentTypes,
  onGeoObjectsFetch,
  infrastructureByYearMap,
  centeredCoordinates,
  infrastructureTypes,
  setInfrastructureType,
  handleMapLoaded,
  controls,
  suggestPosition,
}) => {
  const analyticsParams = useAnalyticsParams({});
  const { isFullscreenOpen, handleFullscreenExit, handleFullscreenEnter } = useFullscreenProps(analyticsParams);
  const previewSrc = getYandexMapPreview(centeredCoordinates);

  const { startBuildingRoute, isRouteMode, handleRouteModeEnter, handleRouteModeExit, handleRouteSuccess } =
    useRouteButtonProps({
      mapRef,
      centeredCoordinates,
      analyticsParams,
    });
  const { onBalloonHtmlFirstFetch, onBalloonOpen } = useGeoObjectBalloon({
    infrastructureByYearMap,
    transportAccessibilityInfrastructure: new Map<TTAInfrastructureType, ITAInfrastructureData>(),
    startBuildingRoute,
    mapService: null,
    mapApi: mapRef.current,
    mainPinCoordinates: centeredCoordinates,
  });

  const handleModalExit = () => {
    handleFullscreenExit();

    if (isRouteMode) {
      handleRouteModeExit();
    }
  };

  const handleMapShown = () => trackInfrastructureShow(analyticsParams.blockType, analyticsParams.profile);

  React.useEffect(() => {
    if (isMapLoaded) {
      const geoObjects = toGeoObjects(infrastructureByYearMap, new Date().getFullYear());
      const geoObjectsByType = geoObjects.filter(item => currentTypes.includes(item.name));

      mapRef.current?.removeAllGeoObjects();
      mapRef.current?.addGeoObjects(geoObjectsByType);
    }
  }, [currentTypes]);

  return (
    <>
      <ActionAfterViewObserver callback={handleMapShown} callbackDelay={VIEWED_TIMEOUT}>
        <InfrastructureLayout isMobile>
          <MobilePreview previewSrc={previewSrc} onClick={handleFullscreenEnter} />
        </InfrastructureLayout>
      </ActionAfterViewObserver>

      <ModalFullscreen open={isFullscreenOpen} theme="white" onClose={handleFullscreenExit}>
        <MobileModalLayout>
          <InfrastructureMapContainer
            apiKey={apiKey}
            suggestApiKey={suggestApiKey}
            mapRef={mapRef}
            controls={controls}
            onGeoObjectsFetch={onGeoObjectsFetch}
            onBalloonHtmlFetch={onBalloonHtmlFirstFetch}
            onMapLoaded={handleMapLoaded}
            onBalloonOpen={onBalloonOpen}
            centeredCoordinates={centeredCoordinates}
            geoObjectNames={currentTypes}
            suggestPosition={suggestPosition}
            onRoutingStart={handleRouteModeEnter}
            onRoutingEnd={handleRouteModeExit}
            onRouteBuildingSuccess={handleRouteSuccess}
          />

          <CloseModalButton key="close-button" onClick={handleModalExit} />

          {!isRouteMode && (
            <>
              <RouteButton key="route-button" onClick={() => startBuildingRoute()} fixed />

              <InfrastructureMapTypes
                analyticsParams={analyticsParams}
                key="infrastructure-map-types"
                types={infrastructureTypes}
                currentTypes={currentTypes}
                onTypesChange={setInfrastructureType}
                fixed
              />
            </>
          )}
        </MobileModalLayout>
      </ModalFullscreen>
    </>
  );
};
