import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

interface ITrackSimilarNewbuildingInfrastructureTypeClickParams {
  blockType: string;
  modelVersion: string | undefined;
  newbuildingIds: number[];
}

export function trackSimilarNewbuildingInfrastructureTypeClick({
  blockType,
  modelVersion,
  newbuildingIds,
}: ITrackSimilarNewbuildingInfrastructureTypeClickParams) {
  decoratedCa('eventSite', {
    action: 'click_object_type_sopr',
    category: 'Infrastructure',
    label: 'similar_newbuilding',
    modelVersion,
    name: 'oldevent',
    page: {
      extra: {
        newbuildingId: newbuildingIds,
        block_type: blockType,
      },
    },
    useLastProducts: true,
  });
}
