import { ModalWindow, UIHeading2 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  InfoPlate,
  InfrastructureFromBuilder,
  InsideIcon,
  ITransportTerm,
  RateInfo,
  TransportAccessibility,
} from 'shared/mf-infrastructure/components/TransportAccessibility';
import { MODAL_Z_INDEX } from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import { useReportInfo } from 'shared/mf-infrastructure/containers/TransportAccessibilityReport/useReportInfo';
import { selectActiveTab } from 'shared/mf-infrastructure/selectors/transportAccessibility';
import {
  IInfrastructureFromBuilderDetail,
  ITransportAccessibilityRate,
} from 'shared/mf-infrastructure/types/transportAccessibilityTypes';

import { trackDepartmentLinkClick, trackRateClick } from '../InfrastructureContainer/tracking';

interface ITransportAcessibilityReportProps {
  transportAccessibilityRate: ITransportAccessibilityRate | null;
  departmentLink: string;
  inside?: boolean;
}

export function TransportAcessibilityReport({
  transportAccessibilityRate,
  departmentLink,
  inside,
}: ITransportAcessibilityReportProps) {
  const info = useReportInfo(transportAccessibilityRate, inside);

  const [isModalOpen, setModal] = React.useState(false);

  const activeTab = useSelector(selectActiveTab);

  const openModal = React.useCallback(() => {
    setModal(true);
    trackRateClick(activeTab as string);
  }, [activeTab]);

  const handleDepartmentLinkClick = React.useCallback(() => {
    trackDepartmentLinkClick(activeTab as string);
  }, [activeTab]);

  const closeModal = React.useCallback(() => setModal(false), []);

  if (!info) {
    return null;
  }

  return (
    <>
      <InfoPlate
        icon={<InsideIcon />}
        content={<RateInfo rate={info.rate} rateText={info.displayRate} />}
        dataTestId={inside ? 'TransportAccessibilityInsideInfo' : 'TransportAccessibilityOutsideInfo'}
        withHorizontalMargin
        onClick={openModal}
      />
      <ModalWindow
        content={
          inside ? (
            <InfrastructureFromBuilder
              departmentLink={departmentLink}
              maxRate={info.displayMaxRate}
              rate={info.displayRate}
              terms={info.rateDetails as IInfrastructureFromBuilderDetail[]}
              onDepartmentLinkClick={handleDepartmentLinkClick}
            />
          ) : (
            <TransportAccessibility
              departmentLink={departmentLink}
              maxRate={info.displayMaxRate}
              rate={info.displayRate}
              terms={info.rateDetails as ITransportTerm[]}
              onDepartmentLinkClick={handleDepartmentLinkClick}
            />
          )
        }
        header={<UIHeading2>{info.title}</UIHeading2>}
        escape
        width={759}
        onClose={closeModal}
        open={isModalOpen}
        size="M"
        zIndex={MODAL_Z_INDEX}
      />
    </>
  );
}
