import { round as trueRound } from './round';

const UNITS: string[] = ['тыс', 'млн', 'млрд'];

export function commarize(value: number, withoutUnitName?: boolean): string {
  if (value >= 1e3) {
    const unit = getUnit(value);
    const unitName = UNITS[unit - 1];

    const result = deunit(value, unit);

    return `${result.toString().replace(/\./g, ',')}${(!withoutUnitName && unitName && ` ${unitName}`) || ''}`;
  }

  return round(value, 1).toString().replace(/\./g, ',');
}

export function getUnit(value: number): number {
  const unit = Math.floor(Math.log(value) / Math.log(1000));

  return unit > 3 ? 3 : unit;
}

export function deunit(value: number, unit: number): number {
  return round(value / 1000 ** unit, 1);
}

export function round(value: number, decimals: number): number {
  return trueRound(value, decimals) as number;
}

export function beautify(value: number): string {
  return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}
