import { IAjaxMapRoundaboutResponse } from 'shared/common/repository-manually-added/monolith-python/ajax/map/roundabout';
import { ISimilarObjectsItem } from 'shared/mf-infrastructure/types/infrastructure';

export function transformSimilarObjectsToInfrastructureItems(
  result: IAjaxMapRoundaboutResponse,
): ISimilarObjectsItem[] {
  return Object.entries(result.data.points).map(([point, item]) => {
    const coordinates = point.split(' ').map(coordinate => parseFloat(coordinate));
    const offerIds = item.offers.map(offer => offer.id);

    const similarOfferItem: ISimilarObjectsItem = {
      coordinates: {
        lat: coordinates[0],
        lng: coordinates[1],
      },
      id: offerIds[0],
      type: 'similar_objects',
      offers: {
        count: item.content.offers_count,
        ids: offerIds,
        link: item.content.link,
        minPrice: item.offers.map(offer => parseFloat(offer.price_rur)).sort((a, b) => a - b)[0],
      },
    };

    return similarOfferItem;
  });
}
