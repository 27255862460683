import * as React from 'react';

import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import { IInfrastructureData, TRegularInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';
import { getInfrastructureByYear } from 'shared/mf-infrastructure/utils/getInfrastructureByYear';

export const useFutureTypes = (
  infrastructureMapByYearMap: Map<number, IInfrastructureData>,
): [(TRegularInfrastructureType | TFutureRoad)[], (year: number | null) => void] => {
  const [futureTypes, setFutureTypesState] = React.useState<(TRegularInfrastructureType | TFutureRoad)[]>([]);

  const setFutureTypes = React.useCallback(
    (selectedYear: number | null) => {
      if (!selectedYear) {
        setFutureTypesState([]);

        return;
      }

      const { futureTypes } = getInfrastructureByYear(infrastructureMapByYearMap, selectedYear);

      setFutureTypesState(futureTypes);
    },
    [infrastructureMapByYearMap],
  );

  return [futureTypes, setFutureTypes];
};
