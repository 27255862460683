import { GenericError } from '@cian/peperrors/shared';

export type Result<T, E> = IOk<T> | IErr<E> | IWarn<T>;

export interface IResult<T, E> {
  Ok: T | never;
  Err: E | never;
}

export interface IOk<T> extends IResult<T, never> {
  Ok: T;
}

export interface IWarn<T> extends IOk<T> {
  warning: IWarning;
}

interface IWarning {
  warnings: Array<GenericError | string>;
}

interface IPrivateOk<T> extends IOk<T> {
  '@type': typeof OkType;
  warning: IWarning;
}

export interface IErr<E> extends IResult<never, E> {
  Err: E;
}

interface IPrivateErr<E> extends IErr<E> {
  '@type': typeof ErrType;
}

const ErrType = Symbol.for('Err');
const OkType = Symbol.for('Ok');

export function Err<E>(err: E): IErr<E> {
  return { Err: err, Ok: undefined, '@type': ErrType } as IPrivateErr<E>;
}

Err.is = (result: IResult<unknown, unknown>): result is IErr<unknown> => {
  if ((result as IPrivateErr<unknown>)['@type'] === ErrType) {
    return true;
  }

  return false;
};

export function Ok<T>(result: T): IOk<T> {
  return { Ok: result, Err: undefined, '@type': OkType } as IPrivateOk<T>;
}

Ok.is = (result: IResult<unknown, unknown>): result is IOk<unknown> => {
  if ((result as IPrivateOk<unknown>)['@type'] === OkType) {
    return true;
  }

  return false;
};

export function Warn<T>(
  result: T,
  warning: GenericError | string | Array<GenericError | string> | IWarn<unknown>,
  chain?: IWarn<unknown>,
): IWarn<T> {
  const problems: Array<GenericError | string> = [];

  if (Array.isArray(warning)) {
    problems.push(...warning);
  } else if (typeof warning === 'string' || warning instanceof GenericError) {
    problems.push(warning);
  } else if (Warn.is(warning)) {
    problems.push(...warning.warning.warnings);
  }

  if (chain) {
    problems.push(...chain.warning.warnings);
  }

  return { Ok: result, Err: undefined, '@type': OkType, warning: { warnings: problems } } as IPrivateOk<T>;
}

Warn.is = (result: IResult<unknown, unknown>): result is IWarn<unknown> => {
  if (Ok.is(result) && 'warning' in result) {
    return true;
  }

  return false;
};
