import { IHttpApi, IHttpApiFetchConfig, IModel, IModelResponse } from '@cian/http-api';

export interface IAjaxMapRoundaboutError {
  status: 'error';
  data: unknown;
}

export interface ISimilarOfferPoint {
  /** признак Спб и ЛО */
  isStPetersburgOrArea: boolean;
  /** признак Москвы и МО */
  isMoscowOrArea: boolean;
  content: {
    /** адрес */
    text: string;
    /** ссылка на поисковую выдачу */
    link: string;
    /** кол-во объявлений */
    offers_count: number;
    /** жк? */
    housing_complex_id?: number;
  };
  /** информация по объявлениям */
  offers: {
    /** id объявления */
    id: string;
    /** ??? */
    fav: string;
    /** цена в рублях */
    price_rur: string;
    /** тип недвижимости, enum? */
    property_type: string;
    /** ссылка на объявление в виде массива html строк */
    link_text: string[];
    /** цвет прина ? */
    color: string;
    /** id агента */
    published_user_id: number;
    /** васы */
    services: string[];
  }[];
  /** легенда */
  stat: {
    /** тип недвижимости */
    property_type: string;
    /** кол-во объявлений */
    count: number;
    /** цвет пина */
    color: string;
  }[];
}

export interface IAjaxMapRoundaboutResponse {
  status: 'ok';
  offer_type: string;
  pik_promo_enabled: boolean;
  url: { favorite: string };
  data: {
    /** кол-во объявлений */
    offers_count: number;
    /**
     * шаблон ссылки на объявление
     * @example "https://www.cian.ru/rent/flat/{{id}}"
     */
    link_template: string;
    points: {
      /** координаты пина в формате "<lat> <lng>" */
      [coordinates: string]: ISimilarOfferPoint;
    };
    /** легенда */
    stat: {
      /** тип недвижимости */
      property_type: string;
      /** кол-во объявлений */
      count: number;
    }[];
  };
}

export interface IAjaxMapRoundaboutResponse200 extends IModelResponse<IAjaxMapRoundaboutResponse> {
  statusCode: 200;
}

export interface IAjaxMapRoundaboutResponse400 extends IModelResponse<IAjaxMapRoundaboutError> {
  statusCode: 400;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAjaxMapRoundaboutRequest extends Record<string, string> {
  'in_polygon[0]': string;
}

export type TAjaxMapRoundaboutResponse = IAjaxMapRoundaboutResponse200 | IAjaxMapRoundaboutResponse400;

export type TGetAjaxMapRoundaboutModel = IModel<IAjaxMapRoundaboutRequest, TAjaxMapRoundaboutResponse>;

const defaultConfig: TGetAjaxMapRoundaboutModel = {
  apiType: 'legacy',
  hostType: 'api',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/ajax/map/roundabout/',
} as TGetAjaxMapRoundaboutModel;

export interface IFetchAjaxMapRoundaboutOptions {
  httpApi: IHttpApi;
  parameters: IAjaxMapRoundaboutRequest;
  config?: IHttpApiFetchConfig;
}

export function createGetAjaxMapRoundaboutModel(parameters: IAjaxMapRoundaboutRequest): TGetAjaxMapRoundaboutModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export async function fetchAjaxMapRoundabout({ httpApi, parameters, config }: IFetchAjaxMapRoundaboutOptions) {
  const { response, statusCode, headers } = await httpApi.fetch(createGetAjaxMapRoundaboutModel(parameters), config);

  return { response, statusCode, headers } as TAjaxMapRoundaboutResponse;
}
