import { TInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureItem } from 'shared/mf-infrastructure/types/transportAccessibility';

export function getTimeWalk(infrastructureItem: TInfrastructureItem | TTAInfrastructureItem): string | undefined {
  if ('timeWalk' in infrastructureItem && infrastructureItem.timeWalk) {
    return `~ ${infrastructureItem.timeWalk} мин. пешком`;
  }

  return undefined;
}
