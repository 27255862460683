import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { trackSimilarObjectsClick } from 'shared/mf-infrastructure/containers/InfrastructureContainer/tracking/trackSimilarObjects';
import {
  selectSimilarObjectsLinkTemplate,
  selectSimilarObjectsMap,
} from 'shared/mf-infrastructure/selectors/similarObjects';

export function useSimilarObjectsBalloonRenderer() {
  const similarObjectsMap = useSelector(selectSimilarObjectsMap);
  const similarObjectsLinkTemplate = useSelector(selectSimilarObjectsLinkTemplate);

  const renderSimilarObjectsBalloon = useCallback(
    (id: string) => {
      const item = similarObjectsMap.get(id);
      if (item) {
        const offers = item.offers.map(offer => ({ id: parseInt(offer.id, 10), offerType: 'offer' }));

        trackSimilarObjectsClick(offers);

        const link =
          item.content.offers_count === 1 && similarObjectsLinkTemplate
            ? similarObjectsLinkTemplate?.replace('{{id}}', item.offers[0].id)
            : item.content.link;

        window.open(link, '_blank');
      }

      return Promise.resolve('');
    },
    [similarObjectsLinkTemplate, similarObjectsMap],
  );

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSimilarObjectsBalloonOpen = useCallback((_id: string) => {}, []);

  return useMemo(
    () => ({
      renderSimilarObjectsBalloon,
      onSimilarObjectsBalloonOpen,
    }),
    [onSimilarObjectsBalloonOpen, renderSimilarObjectsBalloon],
  );
}
