import { createSelector } from '@reduxjs/toolkit';

import { selectSimilarObjectsApiResult } from './selectSimilarObjectsApiResult';

export const selectSimilarObjectsLinkTemplate = createSelector(
  selectSimilarObjectsApiResult,
  (result): string | undefined => {
    if (result) {
      return result.data.link_template;
    }

    return undefined;
  },
);
