const YEARS_COUNT = 5;

export function getMapYears() {
  let year = new Date().getFullYear();
  const lastYear = year + YEARS_COUNT;
  const years = [];

  for (; year < lastYear; year++) {
    years.push(year);
  }

  return years;
}
