import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './MapBalloonWithRoute.css';

export interface IMapBalloonWithRouteProps {
  description?: string;
  routeButtonId: string;
  timeWalk?: string;
  title: string;
  iconBackgroundColor: string;
  dateText?: string;
  icon: React.ReactNode;
}

export function MapBalloonWithRoute({
  description,
  routeButtonId,
  timeWalk,
  title,
  iconBackgroundColor,
  dateText,
  icon,
}: IMapBalloonWithRouteProps) {
  return (
    <div className={styles['balloon']}>
      <div className={styles['icon']} style={{ backgroundColor: iconBackgroundColor }}>
        {icon}
      </div>
      <div>
        <UIText2>{title}</UIText2>
        {dateText && <UIText2 color="gray60_100">{dateText}</UIText2>}
        {description && <UIText2 color="gray60_100">{description}</UIText2>}
        {timeWalk && <UIText2 color="gray60_100">{timeWalk}</UIText2>}
        <div className={styles['route']}>
          <button id={routeButtonId} className={styles['route-button']} type="button">
            Построить маршрут
          </button>
        </div>
      </div>
    </div>
  );
}
