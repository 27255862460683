import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

import { IApplicationContext, IApplicationContextCustom } from 'shared/mf-infrastructure/types/applicationContext';

export function createApplicationContext(): IApplicationContext {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
  };
}
