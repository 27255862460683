// eslint-disable-next-line import/no-restricted-paths
import { ca } from '@cian/analytics';

/**
 * обертка над ca для наследования page из мкс хоста
 * @param eventType 'eventSite' | 'teaser' | etc
 * @param event
 */
export function decoratedCa(eventType: string, event: Record<string, unknown>) {
  // @ts-expect-error Это хак связи микрофронта с мкс. Событие только на фронте.
  const page = window.CianAnalytics?.service.config.page || {};

  return ca(eventType, {
    ...event,
    page: {
      ...page,
      ...(event?.page || {}),
    },
  });
}
