import { IInfrastructureData, TInfrastructureItem } from '../types/infrastructure';

export function toInfrastructureItems(infrastructureByYear: Map<number, IInfrastructureData>) {
  return Array.from(infrastructureByYear.values())
    .flatMap(({ geoObjectsData }) => geoObjectsData)
    .reduce<TInfrastructureItem[]>((acc, datum) => {
      if (datum.infrastructureItem) {
        acc.push(datum.infrastructureItem);
      }

      return acc;
    }, []);
}
