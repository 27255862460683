import { IFutureRoadSchema } from 'shared/common/repositories/infrastructure-caching/entities/newbuilding_infrastructure/FutureRoadSchema';
import { IFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';

export const transformFutureRoads = (futureRoads?: IFutureRoadSchema[]): IFutureRoad[] => {
  if (!futureRoads) {
    return [];
  }

  return futureRoads.map(road => ({
    ...road,
    type: 'futureRoad',
    constructionYearTo: road.constructionYearTo || undefined,
  }));
};
