import { IconFunctionalInformationCircle16, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './RateInfo.css';
import { CommonRateIndicator } from './parts/CommonRateIndicator';

export interface IRateInfoProps {
  rate: number;
  rateText: string;
}

export const RateInfo: React.FC<IRateInfoProps> = ({ rate, rateText }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['text']}>
        <UIText2 fontWeight="bold">Оценка от Дептранса</UIText2>
        <div className={styles['info-icon']}>
          <IconFunctionalInformationCircle16 color="primary_100" />
        </div>
        <div className={styles['rate']}>{rateText}</div>
      </div>
      <CommonRateIndicator rate={rate} />
    </div>
  );
};
