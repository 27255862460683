import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

interface ISoprOffer {
  id: number;
  offerType: string;
}

export function trackSimilarObjectsClick(offers: ISoprOffer[]) {
  decoratedCa('eventSite', {
    category: 'similar_objects',
    action: 'click_similar_on_map',
    modelVersion: '0',
    products: offers,
  });
}
