import { IInfrastructureData } from '../types/infrastructure';

export const getNextAvailableYear = (selectedYear: number, geoObjectsMapByYear: Map<number, IInfrastructureData>) => {
  const infrastructureByYear = geoObjectsMapByYear.get(selectedYear);

  if (infrastructureByYear && !infrastructureByYear.futureTypes.length) {
    const years = geoObjectsMapByYear.keys();

    for (const year of years) {
      const infrastructureInfo = geoObjectsMapByYear.get(year);
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      const futureTypes = infrastructureInfo!.futureTypes;

      if (futureTypes.length) {
        return year;
      }
    }
  }

  return null;
};
