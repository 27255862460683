import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EPanoramaStatus, IPanoramaState } from 'shared/mf-infrastructure/types/panorama';

const initialState: IPanoramaState = {
  status: null,
};

/* istanbul ignore next */
export const panoramaSlice = createSlice({
  initialState,
  name: 'panorama',
  reducers: {
    setStatus: (state, action: PayloadAction<EPanoramaStatus | null>) => {
      state.status = action.payload;
    },
  },
});

export const panoramaActions = panoramaSlice.actions;
