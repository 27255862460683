import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';
import { IGeoObjectOptions } from '@cian/frontend-newbuilding-map-component/es/types/geoObject';

import { ISimilarObjectsItem } from 'shared/mf-infrastructure/types/infrastructure';
import { getSimilarObjectsPinHtml } from 'shared/mf-infrastructure/utils/renderHtmlPins/similarObjectsPin';

import { getSiimilarGeoObjectId } from '../getSiimilarGeoObjectId';

export function similarObjectsItemToGeoObject(item: ISimilarObjectsItem): IGeoObject {
  return {
    type: 'Point',
    name: 'similar_objects',
    options: getPinOptions(item),
  };
}

function getPinOptions(item: ISimilarObjectsItem): IGeoObjectOptions {
  let width = 60;
  let height = 35;
  const html = getSimilarObjectsPinHtml(item);

  /**
   * костыль для получения размеров пина
   */
  if (window) {
    const fakeDiv = window.document.createElement('div');
    fakeDiv.style.opacity = '0';
    fakeDiv.innerHTML = html;
    document.body.appendChild(fakeDiv);

    const rect = fakeDiv.children[0]?.getBoundingClientRect() || { width: 60, height: 35 };
    width = rect.width;
    height = rect.height;

    document.body.removeChild(fakeDiv);
  }

  return {
    coordinates: item.coordinates,
    id: getSiimilarGeoObjectId(item.id),
    html,
    width,
    height,
    yOffset: -height,
    pointGeometry: 'Rectangle',
    xOffset: -width / 2,
    zIndex: 2,
  };
}
