// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetRecommendationsNearNewbuildingRequest,
  TGetRecommendationsNearNewbuildingModel,
  IMappers,
  TGetRecommendationsNearNewbuildingResponse,
  IGetRecommendationsNearNewbuildingResponse,
  IGetRecommendationsNearNewbuildingResponseError,
} from './types';

export const defaultConfig: TGetRecommendationsNearNewbuildingModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'newbuilding-search',
  pathApi: '/v1/get-recommendations-near-newbuilding/',
} as TGetRecommendationsNearNewbuildingModel;

export function createGetRecommendationsNearNewbuildingModel(
  parameters: IGetRecommendationsNearNewbuildingRequest,
): TGetRecommendationsNearNewbuildingModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRecommendationsNearNewbuildingOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetRecommendationsNearNewbuildingRequest;
}

export async function fetchGetRecommendationsNearNewbuilding<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetRecommendationsNearNewbuildingOptions<TResponse200, TResponse400>): Promise<
  TGetRecommendationsNearNewbuildingResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetRecommendationsNearNewbuildingModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRecommendationsNearNewbuildingResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetRecommendationsNearNewbuildingResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetRecommendationsNearNewbuildingResponse;
}
