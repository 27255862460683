import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

import { getAnalyticsObjectType } from './getAnalyticsObjectType';

interface ITrackInfrastructureTypeClickParams {
  profile: string | null;
  infrastructureType: string | null;
  blockType: string;
  isFutureServiceOpened?: boolean;
  similarObjectIds?: number[];
}

export function trackInfrastructureTypeClick({
  profile,
  infrastructureType,
  blockType,
  isFutureServiceOpened = false,
  similarObjectIds,
}: ITrackInfrastructureTypeClickParams) {
  const objectType = getAnalyticsObjectType(infrastructureType);

  decoratedCa('eventSite', {
    action: 'click_object_type_sopr',
    category: 'Infrastructure',
    label: objectType,
    name: 'oldevent',
    useLastProducts: true,
    page: {
      extra: {
        profile,
        block_type: blockType,
        future_open: isFutureServiceOpened,
        similar_object_id: similarObjectIds,
      },
    },
  });
}
