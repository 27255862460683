import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useWidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import { selectCheckedInfrastructureType } from 'shared/mf-infrastructure/selectors/infrastructure';
import { selectActiveTab } from 'shared/mf-infrastructure/selectors/transportAccessibility';
import { EMapService } from 'shared/mf-infrastructure/types/map';

import { getAnalyticsBlockType, getAnalyticsObjectType } from '../tracking';

interface IUseAnalyticsParams {
  selectedServiceType?: EMapService | null;
  serviceTypes?: EMapService[];
}

export interface IAnalyticsParams {
  isFutureServiceOpened: boolean;
  isTransportAccessibilityServiceOpened: boolean;
  blockType: string;
  objectType: string | null;
  profile: string | null;
}

export const useAnalyticsParams = ({
  selectedServiceType = null,
  serviceTypes = [],
}: IUseAnalyticsParams): IAnalyticsParams => {
  const { profile } = useWidgetSettingsContext();
  const checkedInfrastructureType = useSelector(selectCheckedInfrastructureType);
  const transportAccessibilityTab = useSelector(selectActiveTab);

  return useMemo(
    () => ({
      profile: profile || null,
      isFutureServiceOpened: selectedServiceType === EMapService.FutureInfrastructure,
      isTransportAccessibilityServiceOpened: selectedServiceType === EMapService.TransportAccessibility,
      blockType: getAnalyticsBlockType(transportAccessibilityTab, serviceTypes),
      objectType: getAnalyticsObjectType(checkedInfrastructureType),
    }),
    [checkedInfrastructureType, profile, selectedServiceType, serviceTypes, transportAccessibilityTab],
  );
};
