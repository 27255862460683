import { TColors } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './IconStreetCar16.css';

interface IIconStreetCar16Props {
  color?: TColors;
}

// TODO: Заменить на китовую иконку в CD-170478, когда обновится ui-kit до 7 с новыми иконками.
/* istanbul ignore next */
export const IconStreetCar16: React.FC<React.PropsWithChildren<IIconStreetCar16Props>> = ({ color }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={cx(styles['icon'], color === 'white_100' && styles['white'])}
    >
      <path fill="#707A95" d="M12 0H4v2h8V0Z" />
      <path
        fill="#707A95"
        fillRule="evenodd"
        d="M12.208 4H3.792C2.807 4 2 4.792 2 5.76V14h2.333L3 16h2l1.333-2h3.334L11 16h2l-1.333-2H14V5.76C14 4.792 13.194 4 12.208 4ZM12 8H4V6h8v2Zm0 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-7 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
