import { TColors } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './IconScooter16.css';

interface IIconScooter16Props {
  color?: TColors;
}

// TODO: Заменить на китовую иконку в CD-170478, когда обновится ui-kit до 7 с новыми иконками.
/* istanbul ignore next */
export const IconScooter16: React.FC<React.PropsWithChildren<IIconScooter16Props>> = ({ color }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={cx(styles['icon'], color === 'white_100' && styles['white'])}
    >
      <path
        fill="#707A95"
        fillRule="evenodd"
        d="M8 0h4.347l1.521 9.128A3.001 3.001 0 0 1 13 15a3 3 0 0 1-3-3H6a3 3 0 1 1-5.5-1.659V8.5h4.414l1.5 1.5h3.672l1.629-1.629L10.653 2H8V0ZM4 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
