import { IMapCoordinates, IMapGeoObject } from '@cian/frontend-newbuilding-map-component';

import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import { TRegularInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';

interface IGetLineStringGeoObjectOptions {
  name: TRegularInfrastructureType | TFutureRoad;
  coordinates: IMapCoordinates[];
  id: string;
}

export const getLineStringGeoObject = ({ name, id, coordinates }: IGetLineStringGeoObjectOptions): IMapGeoObject => {
  return {
    type: 'LineString',
    name,
    options: {
      coordinates: coordinates.map(({ lng, lat }) => [lng, lat]),
      id,
    },
  };
};
