import { ICustomMainPin } from 'shared/mf-infrastructure/types/customMainPin';
import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import { TRegularInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';

interface IParams {
  name: TRegularInfrastructureType | TFutureRoad | TTAInfrastructureType;
  customMainPin?: ICustomMainPin | null;
}

export function getPinSize({ name, customMainPin }: IParams) {
  if (customMainPin) {
    return 50;
  }

  return name === 'main' ? 54 : 32;
}
