import { IMapGeoObject } from '@cian/frontend-newbuilding-map-component';

interface IGetPolygonGeoObjectOptions {
  name: string;
  coordinates: number[][];
  id: string;
}

export const getPolygonGeoObject = ({ name, coordinates, id }: IGetPolygonGeoObjectOptions): IMapGeoObject => {
  return {
    type: 'Polygon',
    name,
    options: {
      coordinates: [coordinates],
      id,
    },
  };
};
