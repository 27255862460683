import * as React from 'react';

import * as styles from './CommonRateIndicator.css';

export interface ICommonRateIndicatorProps {
  rate: number;
}

export const CommonRateIndicator: React.FC<ICommonRateIndicatorProps> = ({ rate }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['rate']} style={{ width: `${(rate / 10) * 100}%` }} />
    </div>
  );
};
