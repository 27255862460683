import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';
import { createSelector } from '@reduxjs/toolkit';

import { transformResultToGeoObjects } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformResultToGeoObjects';

import { selectInfrastructureApiResult } from './selectInfrastructureApiResult';

export const selectMainGeoObjects = createSelector(selectInfrastructureApiResult, (result): IGeoObject[] => {
  if (result) {
    return transformResultToGeoObjects(result);
  }

  return [];
});
