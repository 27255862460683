import { EMapService } from 'shared/mf-infrastructure/types/map';
import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

const SERVICE_TO_ANALYTICS_LABEL: Record<string, string> = {
  [EMapService.TransportAccessibility]: 'index_button',
  [EMapService.FutureInfrastructure]: 'future_button',
  // Событие клика на сервис маршрутов обрабатывается отдельно.
};

interface ITrackMapServiceClickParams {
  serviceType: EMapService;
  objectType: string | null;
  profile: string | null;
}

export function trackMapServiceClick({ serviceType, objectType, profile }: ITrackMapServiceClickParams) {
  const label = SERVICE_TO_ANALYTICS_LABEL[serviceType];

  if (label) {
    decoratedCa('eventSite', {
      action: 'click_sopr',
      category: 'Infrastructure',
      label: SERVICE_TO_ANALYTICS_LABEL[serviceType],
      name: 'oldevent',
      page: {
        extra: {
          profile,
          object_type: objectType,
        },
      },
      useLastProducts: true,
    });
  }
}
