import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

import { getAnalyticsTabName } from '../getAnalyticsTabName';

export function trackDepartmentLinkClick(tab: string) {
  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Index_transport',
    label: 'index_transport_site',
    name: 'oldevent',
    useLastProducts: true,
    page: {
      extra: {
        block_type: getAnalyticsTabName(tab),
      },
    },
  });
}
