import * as React from 'react';

import * as styles from './TermRateIndicator.css';

export interface ITermRateIndicatorProps {
  ariaLabel?: string;
  rate: number;
}

export const TermRateIndicator: React.FC<ITermRateIndicatorProps> = ({ ariaLabel, rate }) => {
  return (
    <div className={styles['container']} role="img" aria-label={ariaLabel}>
      <div className={styles['rate']} style={{ width: `${rate * 100}%` }} aria-hidden="true" />
    </div>
  );
};
