import {
  OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
  POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES,
} from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import { EOutsideTAInfrastructureType, ITAInfrastructure } from 'shared/mf-infrastructure/types/transportAccessibility';

export const getAvailableOutsideInfrastructureTypes = (
  transportAccessibilityInfrastructure: ITAInfrastructure | undefined,
) => {
  if (!transportAccessibilityInfrastructure?.outside) {
    return [];
  }

  const { outside } = transportAccessibilityInfrastructure;

  const availableInfrastructureTypes: EOutsideTAInfrastructureType[] = [];

  POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES.forEach(infrastructureType => {
    const infrastructure = outside[OUTSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType]];

    if (infrastructure && infrastructure.length > 0) {
      availableInfrastructureTypes.push(infrastructureType);
    }
  });

  return availableInfrastructureTypes;
};
