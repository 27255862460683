import { setCookie } from '@cian/utils/lib/browser/cookie';
import * as React from 'react';

import {
  MAX_DISPLAY_TRANSPORT_ACCESSIBILITY_ONBOARDING_COUNT,
  TRANSPORT_ACCESSIBILITY_ONBOARDING_COOKIE_KEY,
} from 'shared/mf-infrastructure/constants/map';
import { EMapService } from 'shared/mf-infrastructure/types/map';
import { getCookie } from 'shared/mf-infrastructure/utils/cookie';

export function useTransportAccessibilityOnboarding(serviceTypes: EMapService[]) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [timesShown, setTimesShown] = React.useState(0);

  React.useEffect(() => {
    const displayCount = getOnboardingViews();
    const hasTransportAccessibility = serviceTypes.includes(EMapService.TransportAccessibility);

    if (hasTransportAccessibility && displayCount < MAX_DISPLAY_TRANSPORT_ACCESSIBILITY_ONBOARDING_COUNT) {
      setIsOpen(true);
      setTimesShown(displayCount);
    }
  }, [serviceTypes]);

  const closeOnboarding = React.useCallback(() => {
    setIsOpen(false);
    setOnboardingViews(MAX_DISPLAY_TRANSPORT_ACCESSIBILITY_ONBOARDING_COUNT);
  }, []);

  const handleOnboardingShown = React.useCallback(() => {
    setOnboardingViews(timesShown + 1);
  }, [timesShown]);

  return {
    isOnboardingOpen: isOpen,
    closeOnboarding,
    handleOnboardingShown,
  };
}

function getOnboardingViews() {
  if (typeof window !== 'undefined') {
    const cookieValue = getCookie(TRANSPORT_ACCESSIBILITY_ONBOARDING_COOKIE_KEY);

    return Number(cookieValue) || 0;
  }

  return 0;
}

function setOnboardingViews(times: number) {
  setCookie(TRANSPORT_ACCESSIBILITY_ONBOARDING_COOKIE_KEY, `${times}`, { expires: 365 * 86400, secure: false });
}
