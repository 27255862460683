import { useEffect, useMemo } from 'react';

import { GLOBAL_EVENTS } from 'shared/mf-infrastructure/constants/globalEvents';
import { DISTANCE_CALCULATING_ENABLED_CONFIG_KEY } from 'shared/mf-infrastructure/constants/map';
import { IInfrastructureData } from 'shared/mf-infrastructure/types/infrastructure';
import { EMapService } from 'shared/mf-infrastructure/types/map';
import { ITransportAccessibilityRate } from 'shared/mf-infrastructure/types/transportAccessibilityTypes';
import { useApplicationContext } from 'shared/mf-infrastructure/utils/applicationContext';
import { hasFutureInfrastructure } from 'shared/mf-infrastructure/utils/hasFutureInfrastructure';

interface IUseMapServices {
  infrastructureByYearMap: Map<number, IInfrastructureData>;
  transportAccessibilityRate: ITransportAccessibilityRate | null;
}

export const useMapServices = ({ infrastructureByYearMap, transportAccessibilityRate }: IUseMapServices) => {
  const { config } = useApplicationContext();

  const isRouteServiceAvailable = config.get<boolean>(DISTANCE_CALCULATING_ENABLED_CONFIG_KEY) || false;

  const isFutureInfrastructureServiceAvailable = hasFutureInfrastructure(infrastructureByYearMap);

  const isTransportAccessibilityServiceAvailable =
    transportAccessibilityRate?.transport || transportAccessibilityRate?.infrastructure;

  const services = useMemo(() => {
    const services: EMapService[] = [];

    if (isFutureInfrastructureServiceAvailable) {
      services.push(EMapService.FutureInfrastructure);
    }

    if (isTransportAccessibilityServiceAvailable) {
      services.push(EMapService.TransportAccessibility);
    }

    if (isRouteServiceAvailable) {
      services.push(EMapService.Routes);
    }

    return services;
  }, [isFutureInfrastructureServiceAvailable, isTransportAccessibilityServiceAvailable, isRouteServiceAvailable]);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(GLOBAL_EVENTS.mapServices.available, { detail: services }));
  }, [services]);

  return services;
};
