import * as React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'shared/mf-infrastructure/hooks';
import { selectIsFullscreenOpen } from 'shared/mf-infrastructure/selectors/fullscreen';
import { fullscreenActions } from 'shared/mf-infrastructure/slices';

import { IAnalyticsParams } from './useAnalyticsParams';
import { trackFullscreenClose, trackFullscreenOpen } from '../tracking';

interface IUseFullscreenProps {
  isFullscreenOpen: boolean;
  handleFullscreenEnter: () => void;
  handleFullscreenExit: () => void;
}

export const useFullscreenProps = (analyticsParams: IAnalyticsParams): IUseFullscreenProps => {
  const dispatch = useAppDispatch();
  const isFullscreenOpen = useSelector(selectIsFullscreenOpen);

  const handleFullscreenEnter = React.useCallback(() => {
    dispatch(fullscreenActions.setFullscreenOpen());

    const { blockType, profile } = analyticsParams;
    trackFullscreenOpen(blockType, profile);
  }, [analyticsParams, dispatch]);

  const handleFullscreenExit = React.useCallback(() => {
    dispatch(fullscreenActions.setFullscreenClose());

    const { blockType, profile } = analyticsParams;
    trackFullscreenClose(blockType, profile);
  }, [analyticsParams, dispatch]);

  return {
    isFullscreenOpen,
    handleFullscreenEnter,
    handleFullscreenExit,
  };
};
