import { IconBike16, IconCar16, IconCompetitor16, IconParking16, IconPolice16, IconWalk16 } from '@cian/ui-kit';
import React from 'react';

import { IconNoCar16 } from 'shared/mf-infrastructure/components/Icons/IconNoCar16';
import { IconScooter16 } from 'shared/mf-infrastructure/components/Icons/IconScooter16';
import { IMapRowsProps } from 'shared/mf-infrastructure/components/MapRows';
import { EInsideTAInfrastructureType, ITAInfrastructure } from 'shared/mf-infrastructure/types/transportAccessibility';
import { getAvailableInsideInfrastructureTypes } from 'shared/mf-infrastructure/utils/getAvailableInsideInfrastructureTypes';

const INFRASTRUCTURE_TYPE_TO_ROW_MAP: Record<
  EInsideTAInfrastructureType,
  {
    text: string;
    IconComponent: React.FunctionComponent;
  }
> = {
  [EInsideTAInfrastructureType.yardWithoutCars]: {
    text: 'Двор без машин',
    IconComponent: IconNoCar16,
  },
  [EInsideTAInfrastructureType.parking]: {
    text: 'Парковки',
    IconComponent: IconParking16,
  },
  [EInsideTAInfrastructureType.dropOffPickUpArea]: {
    text: 'Быстрая остановка',
    IconComponent: IconCar16,
  },
  [EInsideTAInfrastructureType.bikeParking]: {
    text: 'Велопарковки',
    IconComponent: IconBike16,
  },
  [EInsideTAInfrastructureType.chargingStations]: {
    text: 'Зарядки электромобилей',
    IconComponent: IconCompetitor16,
  },
  [EInsideTAInfrastructureType.scooterParking]: {
    text: 'Парковка самокатов',
    IconComponent: IconScooter16,
  },
  [EInsideTAInfrastructureType.boulevards]: {
    text: 'Пешеходные бульвары',
    IconComponent: IconWalk16,
  },
  [EInsideTAInfrastructureType.trafficSafetyElements]: {
    text: 'Безопасность движения',
    IconComponent: IconPolice16,
  },
};

export const getInsideRows = (transportAccessibilityInfrastructure: ITAInfrastructure | undefined) => {
  const { existingInfrastructureTypes, missingInfrastructureTypes } = getAvailableInsideInfrastructureTypes(
    transportAccessibilityInfrastructure,
  );

  const clickableRows: IMapRowsProps['items'] = existingInfrastructureTypes.map(type => ({
    id: type,
    ...INFRASTRUCTURE_TYPE_TO_ROW_MAP[type],
  }));

  const nonClickableRows: IMapRowsProps['items'] = missingInfrastructureTypes.map(type => ({
    id: type,
    ...INFRASTRUCTURE_TYPE_TO_ROW_MAP[type],
  }));

  return {
    clickableRows,
    nonClickableRows,
  };
};
