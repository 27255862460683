import { IHttpApi, IHttpApiFetchConfig, IModelResponse } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { GenericError, HttpTimeoutError } from '@cian/peperrors/shared';

import { Err, IResult, Ok, ResponseError } from 'shared/common/errors';

interface IAppContext {
  httpApi: IHttpApi;
  logger: ILogger;
}

type TFetchFunction<Request, Response> = (params: {
  httpApi: IHttpApi;
  config: IHttpApiFetchConfig;
  parameters: Request;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => Promise<any | IModelResponse<Response>>;

type TRestApiFunctionWrapper = <Request, Response>(
  fetchFunction: TFetchFunction<Request, Response>,
) => (
  ctx: IAppContext,
  parameters: Request,
  fetchConfig?: IHttpApiFetchConfig,
) => Promise<IResult<Response, HttpTimeoutError | ResponseError | GenericError>>;

export const restApiFunctionWrapper: TRestApiFunctionWrapper =
  fetchFunction => async (ctx, parameters, fetchConfig) => {
    try {
      const { httpApi } = ctx;

      const res = await fetchFunction({
        httpApi,
        parameters,
        config: { ...fetchConfig },
      });

      if (res.statusCode === 200) {
        return Ok(res.response);
      }

      return Err(
        new ResponseError({
          message: getErrorMessage(res, `не удалось получить данные - ${fetchFunction.name}()`),
          domain: fetchFunction.name,
          details: {
            statusCode: res.statusCode,
            response: JSON.stringify(res.response),
          },
        }),
      );
    } catch (error) {
      return Err(error);
    } finally {
      fetchConfig?.cancellationToken?.destroy();
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorMessage(errorLike: any, defaultMessage = 'Произошла ошибка'): string {
  switch (typeof errorLike) {
    case 'string':
      return errorLike;
    case 'object':
      if (errorLike === null) {
        return defaultMessage;
      }
      if (errorLike.hasOwnProperty('message') && typeof errorLike.message === 'string') {
        return errorLike.message;
      } else if (
        errorLike.hasOwnProperty('errors') &&
        Array.isArray(errorLike.errors) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errorLike.errors.some((error: any) => getErrorMessage(error, defaultMessage) !== defaultMessage)
      ) {
        return errorLike.errors.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => getErrorMessage(error, defaultMessage) !== defaultMessage,
        ) as string;
      }

      return defaultMessage;
    default:
      return defaultMessage;
  }
}
