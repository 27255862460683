/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';

import { selectSimilarObjectsApiResult } from './selectSimilarObjectsApiResult';

export const selectSimilarObjectIds = createSelector(selectSimilarObjectsApiResult, (result): number[] => {
  if (result) {
    return Object.entries(result.data.points)
      .map(([_, point]) => point.offers.map(offer => parseInt(offer.id, 10)))
      .flat();
  }

  return [];
});
