import { createSelector } from '@reduxjs/toolkit';

import { INFRASTRUCTURE_TITLE_MAP } from 'shared/mf-infrastructure/constants/infrastructure';
import { transformFutureRoads } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformFutureRoads';
import { EInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';

import { selectInfrastructureApiResult } from './selectInfrastructureApiResult';
import { selectSimilarNewbuildingsApiResult } from '../similarNewbuildings';

export const selectAvailableInfrastructureTypes = createSelector(
  selectInfrastructureApiResult,
  selectSimilarNewbuildingsApiResult,
  (infrastructureResult, similarNewbuildingsResult) => {
    if (infrastructureResult) {
      const existingInfrastructure = infrastructureResult.infrastructure.items;
      const futureInfrastructure = infrastructureResult.future?.infrastructure || [];
      const futureRoads = transformFutureRoads(infrastructureResult.future?.roads);

      const infrastructure = [...existingInfrastructure, ...futureInfrastructure, ...futureRoads];

      const infrastructureTypes = [...new Set(infrastructure.map(({ type }) => type as EInfrastructureType)).values()]
        .map((type: EInfrastructureType) => ({
          type,
          title: INFRASTRUCTURE_TITLE_MAP.get(type) || '',
        }))
        .sort(
          (a, b) =>
            infrastructureResult.infrastructure.order.indexOf(a.type) -
            infrastructureResult.infrastructure.order.indexOf(b.type),
        );

      if (infrastructureResult.similarOffersMapQs) {
        infrastructureTypes.unshift({
          type: EInfrastructureType.SimilarObjects,
          title: INFRASTRUCTURE_TITLE_MAP.get(EInfrastructureType.SimilarObjects) || '',
        });
      }

      if (similarNewbuildingsResult?.newbuildings?.length) {
        infrastructureTypes.push({
          type: EInfrastructureType.SimilarNewbuildings,
          title: INFRASTRUCTURE_TITLE_MAP.get(EInfrastructureType.SimilarNewbuildings) || '',
        });
      }

      return infrastructureTypes;
    }

    return [];
  },
);
