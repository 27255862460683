/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';

import { ISimilarOfferPoint } from 'shared/common/repository-manually-added/monolith-python/ajax/map/roundabout';

import { selectSimilarObjectsApiResult } from './selectSimilarObjectsApiResult';
import { getSiimilarGeoObjectId } from '../../utils/getSiimilarGeoObjectId';

export const selectSimilarObjectsMap = createSelector(
  selectSimilarObjectsApiResult,
  (result): Map<string, ISimilarOfferPoint> => {
    const map: Map<string, ISimilarOfferPoint> = new Map();

    if (result) {
      Object.entries(result.data.points).forEach(([_, item]) => {
        map.set(getSiimilarGeoObjectId(item.offers[0].id), item);
      });
    }

    return map;
  },
);
