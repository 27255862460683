import {
  IGetRecommendationsNearNewbuildingRequest,
  IGetRecommendationsNearNewbuildingResponse,
  fetchGetRecommendationsNearNewbuilding,
} from 'shared/common/repositories/newbuilding-search/v1/get-recommendations-near-newbuilding';
import { restApiFunctionWrapper } from 'shared/common/utils/restApiWrapper/restApiWrapper';

export const fetchSimilarNewbuildings = restApiFunctionWrapper<
  IGetRecommendationsNearNewbuildingRequest,
  IGetRecommendationsNearNewbuildingResponse
>(fetchGetRecommendationsNearNewbuilding);
