import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

export function trackExpandMapServices(objectType: string | null, profile: string | null) {
  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Infrastructure',
    label: 'expand_block',
    name: 'oldevent',
    page: {
      extra: {
        profile,
        object_type: objectType,
      },
    },
    useLastProducts: true,
  });
}
