import * as styles from './SpecialPromoPin.css';

export interface ISpecialPromoPinProps {
  imageUrl: string;
  backgroundColor?: string | null;
}

export function SpecialPromoPin({ imageUrl, backgroundColor }: ISpecialPromoPinProps) {
  return (
    <div className={styles['container']} data-testid="SpecialPromoPin">
      <div
        className={styles['label']}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundColor: backgroundColor || undefined,
        }}
      />
      <div className={styles['pin']} />
    </div>
  );
}
