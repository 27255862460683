import { ErrorLogComponent } from '@cian/error-log-component';
import { ValidationError } from '@cian/peperrors/shared';
import { UiKitContextProvider } from '@cian/ui-kit/context';
import { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';

import { InfrastructureContainer } from 'shared/mf-infrastructure/containers/InfrastructureContainer';
import { WidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import { IApplicationContext } from 'shared/mf-infrastructure/types/applicationContext';
import { TReduxStore } from 'shared/mf-infrastructure/types/redux';
import { IWidgetSettings } from 'shared/mf-infrastructure/types/settings';
import { ApplicationContext } from 'shared/mf-infrastructure/utils/applicationContext';

export type TApplicationProps = Partial<IWidgetSettings> & {
  context: IApplicationContext;
  reduxStore: TReduxStore;
};

export function Application({
  reduxStore,
  context,
  yandexApiKey: passedYandexApiKey,
  yandexSuggestApiKey: passedYandexSuggestApiKey,
  profile,
}: TApplicationProps) {
  const {
    logger,
    custom: { initialDeviceType },
  } = context;

  const yandexApiKey = useMemo(
    () => passedYandexApiKey || context.config.get<string>('yandexApiKey') || '',
    [context.config, passedYandexApiKey],
  );
  const yandexSuggestApiKey = useMemo(
    () => passedYandexSuggestApiKey || context.config.get<string>('yandexSuggestionsApiKey') || '',
    [context.config, passedYandexSuggestApiKey],
  );

  const widgetSettings = useMemo(
    () => ({
      profile,
      yandexApiKey,
      yandexSuggestApiKey,
    }),
    [profile, yandexApiKey, yandexSuggestApiKey],
  );

  useEffect(() => {
    if (!yandexApiKey || !yandexSuggestApiKey) {
      logger.error(
        new ValidationError({
          message: 'yandexApiKey и yandexSuggestApiKey должны быть переданы как пропсы или через конфиг',
          domain: 'mf-infrastructure/App',
        }),
      );
    }
  }, []);

  if (!yandexApiKey || !yandexSuggestApiKey) {
    return null;
  }

  return (
    <ErrorLogComponent logger={logger}>
      <WidgetSettingsContext.Provider value={widgetSettings}>
        <ApplicationContext.Provider value={context}>
          <UiKitContextProvider deviceType={initialDeviceType}>
            <Provider store={reduxStore}>
              <InfrastructureContainer />
            </Provider>
          </UiKitContextProvider>
        </ApplicationContext.Provider>
      </WidgetSettingsContext.Provider>
    </ErrorLogComponent>
  );
}
