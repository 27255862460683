import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { ERouteControlViewType } from '@cian/frontend-newbuilding-map-component';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { InfrastructureMapYearsService } from 'shared/mf-infrastructure/components/InfrastructureMapYearsService';
import { ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES } from 'shared/mf-infrastructure/constants/infrastructure';
import { useFutureTypes } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useFutureTypes';
import { useSelectedYear } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useSelectedYear';
import { useSetGeoObjectsCallbacks } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useSetGeoObjectsCallbacks';
import { IInfrastructureProps } from 'shared/mf-infrastructure/containers/InfrastructureContainer/types';
import { MapServicesContainer } from 'shared/mf-infrastructure/containers/MapServices';
import { TransportAccessibilitySidebarContainer } from 'shared/mf-infrastructure/containers/TransportAccessibilitySidebar';
import { transportAccessibilityActions } from 'shared/mf-infrastructure/slices';
import { EMapService } from 'shared/mf-infrastructure/types/map';
import { TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';
import { getMapYears } from 'shared/mf-infrastructure/utils/getMapYears';
import { hasFutureInfrastructure } from 'shared/mf-infrastructure/utils/hasFutureInfrastructure';

import {
  useAnalyticsParams,
  useFullscreenProps,
  useGeoObjectBalloon,
  usePanoramaProps,
  useRouteButtonProps,
  useRulerProps,
} from './hooks';
import { InfrastructureMapContainer, InfrastructureMapTypes } from './parts';
import { trackInfrastructureShow, trackYearClick } from './tracking';
import { InfrastructureLayout, RouteButton } from '../../components/Infrastructure';
import { VIEWED_TIMEOUT } from '../../constants/viewedBlock';
import { useMapServices, useSelectedMapService } from '../MapServices/hooks';

export const InfrastructureDesktopContainer: React.FC<IInfrastructureProps> = ({
  apiKey,
  suggestApiKey,
  mapRef,
  isMapLoaded,
  isInfrastructureFetched,
  currentTypes,
  infrastructureByYearMap,
  transportAccessibilityInfrastructureByTypeRef,
  transportAccessibilityRate,
  infrastructureRef,
  centeredCoordinates,
  infrastructureTypes,
  setInfrastructureType,
  handleMapLoaded,
  onGeoObjectsFetch,
  controls,
  suggestPosition,
}) => {
  const dispatch = useDispatch();

  const serviceTypes = useMapServices({ infrastructureByYearMap, transportAccessibilityRate });
  const isMapServicesVisible = React.useMemo(() => serviceTypes.length > 0, [serviceTypes]);
  const [mapService, setMapService] = useSelectedMapService(serviceTypes);

  const [futureTypes, setFutureTypes] = useFutureTypes(infrastructureByYearMap);
  const { handleSelectYear, selectedYear, nextAvailableYear } = useSelectedYear(infrastructureByYearMap);
  const hasFutureInfrastructureElements = hasFutureInfrastructure(infrastructureByYearMap);
  const years = React.useMemo(getMapYears, []);

  const analyticsParams = useAnalyticsParams({
    selectedServiceType: mapService,
    serviceTypes,
  });

  const { isPanorama, handlePanoramaSuccessEnter, handleNoPanoramaEnter, handlePanoramaExit } =
    usePanoramaProps(analyticsParams);
  const { handleRulerEnter, handleRulerExit } = useRulerProps(analyticsParams);
  const { startBuildingRoute, isRouteMode, handleRouteModeExit, handleRouteModeEnter, handleRouteSuccess } =
    useRouteButtonProps({ mapRef, centeredCoordinates, analyticsParams });

  const onRouteServiceExit = React.useCallback(() => {
    handleRouteModeExit();
    setMapService(null);
  }, [handleRouteModeExit, setMapService]);

  const isVisible = !isPanorama && !isRouteMode;

  const { isFullscreenOpen, handleFullscreenEnter, handleFullscreenExit } = useFullscreenProps(analyticsParams);

  const { onBalloonHtmlFirstFetch, onBalloonOpen, resetTARouting } = useGeoObjectBalloon({
    infrastructureByYearMap,
    transportAccessibilityInfrastructure: transportAccessibilityInfrastructureByTypeRef.current,
    startBuildingRoute,
    mapService,
    mapApi: mapRef.current,
    mainPinCoordinates: centeredCoordinates,
  });

  const { setRegularGeoObjects, setTransportAccessibilityGeoObjects } = useSetGeoObjectsCallbacks(
    mapRef.current,
    infrastructureByYearMap,
    transportAccessibilityInfrastructureByTypeRef.current,
    currentTypes,
    selectedYear,
    centeredCoordinates,
    resetTARouting,
  );

  const handleChangeTransportAccessibilityService = React.useCallback(
    (infrastructureType: TTAInfrastructureType | null) => {
      setTransportAccessibilityGeoObjects(infrastructureType);
      setInfrastructureType({ activeTypes: ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES, checkedType: infrastructureType });
    },
    [setInfrastructureType, setTransportAccessibilityGeoObjects],
  );

  const handleCloseTransportAccessibilityService = React.useCallback(() => {
    setMapService(null);
    dispatch(transportAccessibilityActions.setActiveTab(null));
    setInfrastructureType({ activeTypes: ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES, checkedType: null });
  }, [dispatch, setInfrastructureType, setMapService]);

  const isMapServiceMenuVisible = isMapServicesVisible && mapService === null;
  const isYearsServiceAvailable = hasFutureInfrastructureElements && mapService === EMapService.FutureInfrastructure;
  const isTransportAccessibilityServiceVisible = mapService === EMapService.TransportAccessibility;
  const isInfrastructureMode = !isPanorama && !isRouteMode && mapService !== EMapService.TransportAccessibility;
  const isRegularInfrastructureChipsVisible = isInfrastructureMode && infrastructureTypes;

  React.useEffect(() => {
    if (isInfrastructureFetched) {
      setFutureTypes(isYearsServiceAvailable ? selectedYear : null);
    }
  }, [selectedYear, isInfrastructureFetched, isYearsServiceAvailable, setFutureTypes]);

  React.useEffect(() => {
    if (isMapLoaded && isInfrastructureFetched && !isTransportAccessibilityServiceVisible) {
      setRegularGeoObjects(currentTypes, selectedYear, mapService !== EMapService.FutureInfrastructure);
    }
  }, [
    mapService,
    currentTypes,
    selectedYear,
    isYearsServiceAvailable,
    isInfrastructureFetched,
    isTransportAccessibilityServiceVisible,
    isMapLoaded,
    setRegularGeoObjects,
  ]);

  const handleYearChange = React.useCallback(
    (year: number) => {
      handleSelectYear(year);
      trackYearClick({
        blockType: analyticsParams.blockType,
        objectType: analyticsParams.objectType,
        year,
        profile: analyticsParams.profile,
      });
    },
    [analyticsParams, handleSelectYear],
  );

  const handleServiceClick = React.useCallback(
    (serviceType: EMapService | null) => {
      setMapService(serviceType);

      if (serviceType === EMapService.TransportAccessibility) {
        setInfrastructureType({ activeTypes: [], checkedType: null });
      }

      if (serviceType === EMapService.Routes) {
        startBuildingRoute(undefined, ERouteControlViewType.stickyLeft);
      }
    },
    [setInfrastructureType, setMapService, startBuildingRoute],
  );

  const handleMapShown = () => trackInfrastructureShow(analyticsParams.blockType, analyticsParams.profile);

  return (
    <ActionAfterViewObserver callback={handleMapShown} callbackDelay={VIEWED_TIMEOUT}>
      <InfrastructureLayout>
        <InfrastructureMapContainer
          apiKey={apiKey}
          suggestApiKey={suggestApiKey}
          mapRef={mapRef}
          controls={controls}
          onGeoObjectsFetch={onGeoObjectsFetch}
          onBalloonHtmlFetch={onBalloonHtmlFirstFetch}
          onMapLoaded={handleMapLoaded}
          onBalloonOpen={onBalloonOpen}
          centeredCoordinates={centeredCoordinates}
          geoObjectNames={currentTypes}
          suggestPosition={suggestPosition}
          onFullscreenEnter={handleFullscreenEnter}
          onFullscreenExit={handleFullscreenExit}
          onRoutingStart={handleRouteModeEnter}
          onRoutingEnd={onRouteServiceExit}
          onRouteBuildingSuccess={handleRouteSuccess}
          onNoPanoramaEnter={handleNoPanoramaEnter}
          onPanoramaExit={handlePanoramaExit}
          onPanoramaSuccessEnter={handlePanoramaSuccessEnter}
          onRulerEnter={handleRulerEnter}
          onRulerExit={handleRulerExit}
        />

        {isVisible && (
          <>
            {!isMapServicesVisible && (
              <RouteButton key="route-button" fixed={isFullscreenOpen} onClick={() => startBuildingRoute()} />
            )}

            {isRegularInfrastructureChipsVisible && (
              <InfrastructureMapTypes
                analyticsParams={analyticsParams}
                key="infrastructure-map-types"
                fixed={isFullscreenOpen}
                types={infrastructureTypes}
                futureTypes={futureTypes}
                currentTypes={currentTypes}
                onTypesChange={setInfrastructureType}
              />
            )}

            {isMapServiceMenuVisible && (
              <MapServicesContainer
                analyticsParams={analyticsParams}
                key="map-services"
                onServiceClick={handleServiceClick}
                serviceTypes={serviceTypes}
                fixed={isFullscreenOpen}
              />
            )}

            {isYearsServiceAvailable && (
              <InfrastructureMapYearsService
                key="infrastructure-map-years-service"
                nextAvailableYear={nextAvailableYear}
                selectedYear={selectedYear}
                years={years}
                onChange={handleYearChange}
                onClose={() => setMapService(null)}
                fixed={isFullscreenOpen}
              />
            )}

            {isTransportAccessibilityServiceVisible && (
              <TransportAccessibilitySidebarContainer
                key="transport-accessibility-sidebar"
                transportAccessibilityRate={transportAccessibilityRate}
                onTransportAccessibilityChange={handleChangeTransportAccessibilityService}
                infrastructureRef={infrastructureRef}
                onClose={handleCloseTransportAccessibilityService}
                fixed={isFullscreenOpen}
              />
            )}
          </>
        )}
      </InfrastructureLayout>
    </ActionAfterViewObserver>
  );
};
