import {
  EInsideTAInfrastructureType,
  EOutsideTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

const INFRASTRUCTURE_TYPE_TO_ANALYTICS_OBJECT_TYPE: Record<string, string> = {
  [EOutsideTAInfrastructureType.busStops]: 'bus_stop',
  [EOutsideTAInfrastructureType.tramStops]: 'trams',
  [EOutsideTAInfrastructureType.metro]: 'metro',
  [EOutsideTAInfrastructureType.parking]: 'parking',
  [EOutsideTAInfrastructureType.bikeParking]: 'bike_parking',
  [EOutsideTAInfrastructureType.chargingStations]: 'car_charging',
  [EOutsideTAInfrastructureType.scooterParking]: 'scooter_parking',

  [EInsideTAInfrastructureType.boulevards]: 'boulevard',
  [EInsideTAInfrastructureType.trafficSafetyElements]: 'safety',
  [EInsideTAInfrastructureType.yardWithoutCars]: 'no_cars',
  [EInsideTAInfrastructureType.scooterParking]: 'scooter_parking',
  [EInsideTAInfrastructureType.dropOffPickUpArea]: 'short_stop',
  [EInsideTAInfrastructureType.parking]: 'parking',
  [EInsideTAInfrastructureType.bikeParking]: 'bike_parking',
  [EInsideTAInfrastructureType.chargingStations]: 'car_charging',
};

export function getAnalyticsObjectType(infrastructureType: string | null) {
  return infrastructureType
    ? INFRASTRUCTURE_TYPE_TO_ANALYTICS_OBJECT_TYPE[infrastructureType] || infrastructureType
    : infrastructureType;
}
