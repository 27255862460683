import { createSlice } from '@reduxjs/toolkit';

import { loadSimilarObjects } from 'shared/mf-infrastructure/thunks';
import { TSimilarObjectsState } from 'shared/mf-infrastructure/types/similarObjects';

const initialState: TSimilarObjectsState = {
  status: 'idle',
  data: null,
  points: {},
};

/* istanbul ignore next */
export const similarObjectsSlice = createSlice({
  initialState,
  name: 'similarObjects',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadSimilarObjects.pending, state => {
        state.status = 'pending';
      })
      .addCase(loadSimilarObjects.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.data = action.payload;
        state.points = action.payload.data.points;
      })
      .addCase(loadSimilarObjects.rejected, state => {
        state.status = 'rejected';
      });
  },
});
