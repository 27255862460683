import { createSelector } from '@reduxjs/toolkit';

import { selectInfrastructureApiResult } from './selectInfrastructureApiResult';

export const selectInfrastructureSimilarObjectsQueryParams = createSelector(
  selectInfrastructureApiResult,
  (result): Record<string, string> | undefined => {
    if (result?.similarOffersMapQs) {
      const queryParams = new URLSearchParams(result.similarOffersMapQs);

      return Object.fromEntries(queryParams.entries());
    }

    return undefined;
  },
);
