/* istanbul ignore next */
export const getCookie = (name: string): string | undefined => {
  if (process.env.IS_BROWSER_BUILD) {
    const matches = window.document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  return undefined;
};
