import { makeContextModule } from '@cian/react-utils';

import { IWidgetSettings } from 'shared/mf-infrastructure/types/settings';

export const {
  Context: WidgetSettingsContext,
  useContext: useWidgetSettingsContext,
  withContext: withWidgetSettingsContext,
} = makeContextModule<IWidgetSettings>({
  contextName: 'WidgetSettingsContext',
});
