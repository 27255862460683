import * as React from 'react';

import { InfrastructureTypeRows } from 'shared/mf-infrastructure/components/TransportAccessibility';
import { useWidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import {
  ETransportAccessibilityTab,
  ITAInfrastructure,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

import { getInsideRows } from './helpers';
import { getAnalyticsBlockType, trackInfrastructureTypeClick } from '../InfrastructureContainer/tracking';

export interface ITransportAccessibilityOutsideProps {
  rate: React.ReactNode;
  onTransportAccessibilityChange: (infrastructureType: TTAInfrastructureType | null) => void;
  transportAccessibilityInfrastructure?: ITAInfrastructure;
}

export const TransportAccessibilityInside: React.FC<ITransportAccessibilityOutsideProps> = ({
  onTransportAccessibilityChange,
  rate,
  transportAccessibilityInfrastructure,
}) => {
  const { profile } = useWidgetSettingsContext();

  const { clickableRows, nonClickableRows } = React.useMemo(
    () => getInsideRows(transportAccessibilityInfrastructure),
    [transportAccessibilityInfrastructure],
  );

  const [selectedType, setSelectedType] = React.useState(clickableRows[0]?.id);

  React.useEffect(() => {
    onTransportAccessibilityChange(selectedType as TTAInfrastructureType);

    return () => onTransportAccessibilityChange(null);
  }, []);

  const handleRowClick = React.useCallback(
    (infrastructureType: TTAInfrastructureType) => {
      onTransportAccessibilityChange(infrastructureType);
      setSelectedType(infrastructureType);

      const blockType = getAnalyticsBlockType(ETransportAccessibilityTab.Inside, []);
      trackInfrastructureTypeClick({
        profile: profile || null,
        blockType,
        infrastructureType,
      });
    },
    [profile, onTransportAccessibilityChange],
  );

  return (
    <>
      {rate}
      <InfrastructureTypeRows
        clickableRows={clickableRows}
        nonClickableRows={nonClickableRows}
        onRowClick={handleRowClick}
        selectedItemId={selectedType}
      />
    </>
  );
};
