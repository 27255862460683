import { TInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import {
  EInsideTAInfrastructureType,
  EOutsideTAInfrastructureType,
  TTAInfrastructureItem,
} from 'shared/mf-infrastructure/types/transportAccessibility';

export function getDescription(infrastructureItem: TInfrastructureItem | TTAInfrastructureItem): string | undefined {
  switch (infrastructureItem.type) {
    case EOutsideTAInfrastructureType.busStops:
      if (
        'routes' in infrastructureItem &&
        Array.isArray(infrastructureItem.routes) &&
        infrastructureItem.routes.length > 0
      ) {
        return `Автобусы: ${infrastructureItem.routes.join(', ')}`;
      }

      return undefined;
    case EOutsideTAInfrastructureType.tramStops:
      if (
        'routes' in infrastructureItem &&
        Array.isArray(infrastructureItem.routes) &&
        infrastructureItem.routes.length > 0
      ) {
        return `Трамваи: ${infrastructureItem.routes.join(', ')}`;
      }

      return undefined;
    case EOutsideTAInfrastructureType.parking:
    case EInsideTAInfrastructureType.parking:
      if ('capacity' in infrastructureItem && infrastructureItem.capacity) {
        return `${infrastructureItem.capacity} машино-мест`;
      }

      return undefined;
    case EInsideTAInfrastructureType.yardWithoutCars:
      return infrastructureItem.description;
    default:
      return undefined;
  }
}
