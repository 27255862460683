import { TColors } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './IconNoCar16.css';

interface IIconNoCar16Props {
  color?: TColors;
}

// TODO: Заменить на китовую иконку в CD-170478, когда обновится ui-kit до 7 с новыми иконками.
/* istanbul ignore next */
export const IconNoCar16: React.FC<React.PropsWithChildren<IIconNoCar16Props>> = ({ color }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={cx(styles['icon'], color === 'white_100' && styles['white'])}
    >
      <path
        fill="#707A95"
        fillRule="evenodd"
        d="M12.5 1H15L2.5 15H0l1.803-2.02A1 1 0 0 1 1 12V8l1-1 .84-4.196A1 1 0 0 1 3.82 2h7.787l.893-1ZM9.821 4H4.64l-.2 1h.004L4 7h3.143L9.82 4ZM4.883 9.53a1 1 0 1 0-1.25 1.4l1.25-1.4ZM13.66 5.3 6.786 13H11v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0 1-1V8l-1-1-.34-1.7ZM12 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
