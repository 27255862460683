import { getConstructionYears } from './getConstructionYears';
import { getMapYears } from './getMapYears';
import { TInfrastructureItem } from '../types/infrastructure';

export function hasFutureObjects(infrastructureItems: TInfrastructureItem[]) {
  const mapYears = getMapYears();

  return infrastructureItems.some(item => {
    if ('constructionYearFrom' in item) {
      const constructionYears = getConstructionYears(item);

      return constructionYears.some(constructionYear => mapYears.includes(constructionYear));
    }

    return false;
  });
}
