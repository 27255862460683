import { IMapCoordinates, IMapGeoObject } from '@cian/frontend-newbuilding-map-component';
import * as ReactDOMServer from 'react-dom/server';

import { MapLabel } from 'shared/mf-infrastructure/components/MapLabel';

interface IGetRouteLabelOptions {
  id: string;
  coordinates: IMapCoordinates;
  text: string;
}

export const getRouteLabel = ({ text, coordinates, id }: IGetRouteLabelOptions): IMapGeoObject => {
  return {
    type: 'Point',
    name: 'geoLabel',
    options: {
      coordinates,
      html: ReactDOMServer.renderToStaticMarkup(<MapLabel text={text} />),
      id,
      yOffset: -40,
      xOffset: 16,
      height: 25,
      width: 0,
    },
  };
};
