import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface IMapBalloonProps {
  title: string;
  icon: React.ReactNode;
  iconBackgroundColor: string;
  timeWalk?: string;
  dateText?: string;
  description?: string;
}

export const MapBalloon: React.FC<IMapBalloonProps> = ({
  title,
  dateText,
  timeWalk,
  description,
  icon,
  iconBackgroundColor,
}) => {
  return (
    <div className={style['balloon']}>
      <div className={style['icon']} style={{ backgroundColor: iconBackgroundColor }}>
        {icon}
      </div>
      <div className={style['content']}>
        <UIText2>{title}</UIText2>
        {dateText && <UIText2 color="gray60_100">{dateText}</UIText2>}
        {description && <UIText2 color="gray60_100">{description}</UIText2>}
        {timeWalk && <UIText2 color="gray60_100">{timeWalk}</UIText2>}
      </div>
    </div>
  );
};
