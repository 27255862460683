import {
  INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP,
  POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES,
} from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import { EInsideTAInfrastructureType, ITAInfrastructure } from 'shared/mf-infrastructure/types/transportAccessibility';

export const getAvailableInsideInfrastructureTypes = (
  transportAccessibilityInfrastructure: ITAInfrastructure | undefined,
) => {
  if (!transportAccessibilityInfrastructure?.inside) {
    return {
      existingInfrastructureTypes: [],
      missingInfrastructureTypes: POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES,
    };
  }

  const existingInfrastructureTypes: EInsideTAInfrastructureType[] = [];
  const missingInfrastructureTypes: EInsideTAInfrastructureType[] = [];

  const { inside } = transportAccessibilityInfrastructure;

  POSSIBLE_INSIDE_INFRASTRUCTURE_TYPES.forEach(infrastructureType => {
    const infrastructure = inside[INSIDE_INFRASTRUCTURE_TYPE_TO_PROPERTY_MAP[infrastructureType]];

    if (infrastructure) {
      if (infrastructure.length > 0) {
        existingInfrastructureTypes.push(infrastructureType);
      } else {
        missingInfrastructureTypes.push(infrastructureType);
      }
    }
  });

  return {
    existingInfrastructureTypes,
    missingInfrastructureTypes,
  };
};
