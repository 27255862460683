import * as React from 'react';

import * as styles from './RateMark.css';

export interface IRateMarkProps {
  icon: React.ReactNode;
  maxRate: string;
  rate: string;
}

export const RateMark: React.FC<IRateMarkProps> = ({ icon, maxRate, rate }) => {
  return (
    <div className={styles['container']} data-testid="RateMark">
      {icon}
      <div className={styles['rate']}>{rate}</div>
      <div className={styles['rate-from']}>из {maxRate}</div>
    </div>
  );
};
