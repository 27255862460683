export enum EOutsideTAInfrastructureType {
  busStops = 'busStopsOutside',
  tramStops = 'tramStopsOutside',
  metro = 'metroOutside',
  parking = 'parkingOutside',
  bikeParking = 'bikeParkingOutside',
  chargingStations = 'chargingStationsOutside',
  scooterParking = 'scooterParkingOutside',
}

export enum EInsideTAInfrastructureType {
  yardWithoutCars = 'yardWithoutCarsInside',
  parking = 'parkingInside',
  dropOffPickUpArea = 'dropOffPickUpAreaInside',
  bikeParking = 'bikeParkingInside',
  chargingStations = 'chargingStationsInside',
  scooterParking = 'scooterParkingInside',
  boulevards = 'boulevardsInside',
  trafficSafetyElements = 'trafficSafetyElementsInside',
}

export type TTAInfrastructureType = EOutsideTAInfrastructureType | EInsideTAInfrastructureType;
