import { UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './DepartmentLink.css';

export interface IDepartmentLinkProps {
  href: string;
  onClick?: () => void;
  size: 'large' | 'small';
}

export const DepartmentLink: React.FC<IDepartmentLinkProps> = ({ href, onClick, size }) => {
  const UIText = size === 'large' ? UIText1 : UIText2;

  return (
    <UIText>
      Подробнее о рейтинге на{' '}
      <a
        className={styles['link']}
        href={href}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
        data-testid="DepartmentLink"
      >
        сайте
      </a>{' '}
      от&nbsp;Департамента транспорта Москвы
    </UIText>
  );
};
