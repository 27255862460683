import { IMapCoordinates, IMapGeoObject } from '@cian/frontend-newbuilding-map-component';
import * as ReactDOMServer from 'react-dom/server';

import { MapPin } from 'shared/mf-infrastructure/components/Infrastructure';
import { ICustomMainPin } from 'shared/mf-infrastructure/types/customMainPin';
import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import {
  EInfrastructureView,
  TInfrastructureKind,
  TRegularInfrastructureType,
} from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';

import { getPinSize } from './getPinSize';

interface IGetGeoObjectOptions {
  type: TRegularInfrastructureType | TFutureRoad | TTAInfrastructureType;
  coordinates: IMapCoordinates;
  id: string;
  customMainPin?: ICustomMainPin | null;
  view?: EInfrastructureView;
  kind?: TInfrastructureKind;
}

export const getGeoObject = ({
  type,
  kind,
  coordinates,
  id,
  customMainPin,
  view = EInfrastructureView.ExistingObject,
}: IGetGeoObjectOptions): IMapGeoObject => {
  const size = getPinSize({ name: type, customMainPin });
  const color = view === EInfrastructureView.FutureObject ? 'green' : 'blue';

  const geoObject: IMapGeoObject = {
    type: 'Point',
    name: type,
    options: {
      coordinates,
      html: ReactDOMServer.renderToStaticMarkup(
        <MapPin type={type} kind={kind} color={color} customMainPin={customMainPin} />,
      ),
      id,
      width: size,
      height: size,
    },
  };

  /**
   * хак с переопределением zIndex у пинов
   * по умолчанию в либе @cian/frontend-newbuilding-map-component устанавливается zIndex = 701
   * при таком значении zIndex есть артефакты со сменой zIndex либой карты при наведении на пины
   * поэтому устанавливаем zIndex = 2 для всех пинов, zIndex = 1 для полигонов
   * основной пин должен быть поверх всех остальных пинов, поэтому устанавливаем zIndex = 3
   * при таких значениях zIndex артефактов не наблюдается
   */
  if (type === 'main') {
    geoObject.options.zIndex = 3;
  }

  if (type !== 'main') {
    geoObject.options.zIndex = geoObject.type === 'Point' ? 2 : 1;
    geoObject.options.yOffset = -size / 2;
  }

  return geoObject;
};
