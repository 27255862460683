/* eslint-disable @typescript-eslint/no-unused-vars */
import { IBBox } from '@cian/frontend-newbuilding-map-component/es/types';
import { ValidationError } from '@cian/peperrors/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Err } from 'shared/common/errors';
import { IAjaxMapRoundaboutResponse } from 'shared/common/repository-manually-added/monolith-python/ajax/map/roundabout';
import { selectInfrastructureSimilarObjectsQueryParams } from 'shared/mf-infrastructure/selectors/infrastructure';
import { selectSubdomain } from 'shared/mf-infrastructure/selectors/page';
import { fetchSimilarObjects } from 'shared/mf-infrastructure/services/fetchSimilarObjects/fetchSimilarObjects';
import { IThunkApi } from 'shared/mf-infrastructure/types/redux';
import { transformBboxToPolygon } from 'shared/mf-infrastructure/utils/transformBboxToPolygon';

/* istanbul ignore next */
export const loadSimilarObjects = createAsyncThunk<IAjaxMapRoundaboutResponse, IBBox, IThunkApi>(
  'similarObjects/loadData',
  async (bbox, thunkApi) => {
    const { rejectWithValue, extra, getState } = thunkApi;
    const state = getState();
    const queryParams = selectInfrastructureSimilarObjectsQueryParams(state);
    const subdomain = selectSubdomain(state);

    if (!queryParams) {
      return rejectWithValue(
        new ValidationError({
          message: 'в состояние отсутствуют параметры запроса',
          domain: 'thunks/loadSimilarObjects',
        }),
      );
    }

    const similarObjectsResult = await fetchSimilarObjects(
      extra,
      {
        ...queryParams,
        'in_polygon[0]': transformBboxToPolygon(bbox),
      },
      {
        subdomain,
        transformBodyToLegacyString: true,
      },
    );

    if (Err.is(similarObjectsResult)) {
      return rejectWithValue(similarObjectsResult.Err);
    }

    return similarObjectsResult.Ok;
  },
  {
    condition: (_, _api) => {
      const { getState } = _api;

      const state = getState();

      return state.similarObjects.status !== 'pending';
    },
  },
);
