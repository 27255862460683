import { POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES } from 'shared/mf-infrastructure/constants/transportAccessibilityInfrastructure';
import {
  EOutsideTAInfrastructureType,
  ETransportAccessibilityTab,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';
import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

import { getAnalyticsObjectType } from '../getAnalyticsObjectType';
import { getAnalyticsTabName } from '../getAnalyticsTabName';

export function trackTransportAccessibilityPinClick(infrastructureType: TTAInfrastructureType) {
  const tab = POSSIBLE_OUTSIDE_INFRASTRUCTURE_TYPES.includes(infrastructureType as EOutsideTAInfrastructureType)
    ? ETransportAccessibilityTab.Outside
    : ETransportAccessibilityTab.Inside;

  decoratedCa('eventSite', {
    action: 'click_sopr',
    category: 'Index_transport',
    label: 'pin',
    name: 'oldevent',
    useLastProducts: true,
    page: {
      extra: {
        object_type: getAnalyticsObjectType(infrastructureType),
        block_type: getAnalyticsTabName(tab),
      },
    },
  });
}
