import { IMapGeoObject } from '@cian/frontend-newbuilding-map-component';

import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import {
  EInfrastructureView,
  IInfrastructureData,
  ISimilarObjectsItem,
  TInfrastructureItem,
  TRegularInfrastructureType,
} from 'shared/mf-infrastructure/types/infrastructure';
import { getGeoObject } from 'shared/mf-infrastructure/utils/getGeoObject';
import { getLineStringGeoObject } from 'shared/mf-infrastructure/utils/getLineStringGeoObject';
import { getMapYears } from 'shared/mf-infrastructure/utils/getMapYears';
import { similarObjectsItemToGeoObject } from 'shared/mf-infrastructure/utils/infrastructureItems';

import { getConstructionYears } from './getConstructionYears';

export function getInfrastructureMapByYear(
  infrastructureItems: TInfrastructureItem[],
  mainGeoObjects: IMapGeoObject[],
  isFutureInfrastructureServiceVisible = false,
) {
  const map = new Map<number, IInfrastructureData>();

  const years = getMapYears();

  years.forEach(year => {
    const futureTypes = new Set<TRegularInfrastructureType | TFutureRoad>();

    infrastructureItems.forEach(item => {
      if ('constructionYearFrom' in item) {
        // TODO: Одна из веток будет удалена в NB-1172
        if (isFutureInfrastructureServiceVisible) {
          if (item.constructionYearFrom <= year) {
            addItemToMap(item, year, map, EInfrastructureView.FutureObject);
            futureTypes.add(item.type);
          }

          return;
        }

        const constructionYears = getConstructionYears(item);

        if (constructionYears.includes(year)) {
          addItemToMap(item, year, map, EInfrastructureView.FutureObject);
          futureTypes.add(item.type);
        }

        return;
      }

      addItemToMap(item, year, map, EInfrastructureView.ExistingObject);
    });

    const currentYearGeoObjectsData = map.get(year)?.geoObjectsData || [];

    mainGeoObjects.forEach(geoObject => {
      currentYearGeoObjectsData.push({ geoObject, infrastructureItem: null });
    });

    map.set(year, {
      geoObjectsData: currentYearGeoObjectsData,
      futureTypes: [...futureTypes],
    });
  });

  return map;
}

function addItemToMap(
  item: TInfrastructureItem,
  year: number,
  map: Map<number, IInfrastructureData>,
  view: EInfrastructureView,
) {
  const { type, coordinates, id } = item;

  const geoObjectsData = map.get(year)?.geoObjectsData || [];

  if (type === 'futureRoad') {
    const lineStringGeoObject = getLineStringGeoObject({
      name: type,
      coordinates: item.lineString,
      id: `${id}_line_string`,
    });

    const pontGeoObject = getGeoObject({ type, coordinates, id: `${id}_point`, view });

    geoObjectsData.push(
      {
        geoObject: lineStringGeoObject,
        infrastructureItem: item,
      },
      {
        geoObject: pontGeoObject,
        infrastructureItem: item,
      },
    );
  } else if (type === 'similar_objects') {
    geoObjectsData.push({
      geoObject: similarObjectsItemToGeoObject(item as ISimilarObjectsItem),
      infrastructureItem: item,
    });
  } else {
    const { kind } = item;

    geoObjectsData.push({
      geoObject: getGeoObject({ type, kind, coordinates, id, view }),
      infrastructureItem: item,
    });
  }

  map.set(year, { geoObjectsData, futureTypes: [] });
}
