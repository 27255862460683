import { createSelector } from '@reduxjs/toolkit';

import { ICoordinates } from 'shared/mf-infrastructure/types/map';

import { selectInfrastructureApiResult } from './selectInfrastructureApiResult';

const DEFAULT_MAP_CENTER: ICoordinates = { lng: 37.621202, lat: 55.753544 };
export const selectInfrastructureCenterCoordinates = createSelector(
  selectInfrastructureApiResult,
  (result): ICoordinates => {
    if (result) {
      return result.center;
    }

    return DEFAULT_MAP_CENTER;
  },
);
