import { createSlice } from '@reduxjs/toolkit';

import { loadSimilarNewbuildings } from 'shared/mf-infrastructure/thunks';
import { TSimilarNewbuildingsState } from 'shared/mf-infrastructure/types/similarNewbuildings';

const initialState: TSimilarNewbuildingsState = {
  status: 'idle',
  data: null,
};

/* istanbul ignore next */
export const similarNewbuildingsSlice = createSlice({
  initialState,
  name: 'similarNewbuildings',
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadSimilarNewbuildings.pending, state => {
        state.status = 'pending';
      })
      .addCase(loadSimilarNewbuildings.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.data = action.payload;
      })
      .addCase(loadSimilarNewbuildings.rejected, state => {
        state.status = 'rejected';
      });
  },
});
