import { ISimilarNewbuildingItem } from 'shared/mf-infrastructure/types/infrastructure';
import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

export function trackSimilarNewbuildingLinkClick(similarNewbuilding: ISimilarNewbuildingItem) {
  const { modelVersion, newbuildingId } = similarNewbuilding;

  decoratedCa('eventSite', {
    action: 'click',
    category: 'Infrastructure',
    label: 'similar_newbuilding',
    modelVersion,
    name: 'oldevent',
    page: {
      extra: {
        newbuildingId,
      },
    },
    useLastProducts: true,
  });
}
