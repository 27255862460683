import { decoratedCa } from 'shared/mf-infrastructure/utils/analytics';

export function trackRouteModeExit() {
  decoratedCa('eventSite', {
    action: 'close_sopr',
    category: 'Infrastructure',
    label: 'route_button',
    name: 'oldevent',
    useLastProducts: true,
  });
}
