import { IconButton, IconClose16, UIHeading2, UIText3 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import * as styles from './InfrastructureMapYearsService.css';
import { Year } from './parts/Year';

interface IYearsServiceProps {
  selectedYear: number;
  nextAvailableYear: number | null;
  years: number[];
  onChange: (value: number) => void;
  onClose: () => void;
  fixed: boolean;
}

export const InfrastructureMapYearsService: React.FC<IYearsServiceProps> = ({
  selectedYear,
  nextAvailableYear,
  years,
  onChange,
  onClose,
  fixed,
}) => {
  return (
    <div className={clsx(styles['wrapper'], fixed && styles['fixed'])} data-testid="InfrastructureMapYearsService">
      <div className={styles['close-button']} aria-label="Закрыть">
        <IconButton icon={<IconClose16 />} onClick={onClose} />
      </div>
      <UIHeading2>Будущее района</UIHeading2>
      <div className={styles['warning']}>
        <UIText3 color="gray60_100">Данные на сегодняшний день</UIText3>
      </div>
      <div className={styles['years']}>
        {years.map(year => (
          <Year
            key={year}
            year={year}
            onChange={onChange}
            checked={year === selectedYear}
            nextAvailableYear={nextAvailableYear}
          />
        ))}
      </div>
    </div>
  );
};
